import { process } from '../env';
import { commonFetch } from './apiHelper';

export function checkProductItem(productItemCoded: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-items/check`;
  return commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: productItemCoded,
    }),
  })
    .then((response) => response.json());
}

export function consumeProductItem(id: string, productSpecificationId: string) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-items/${id}/consume`;
  return commonFetch(requestURL, {
    method: 'POST',
    body: JSON.stringify({ productSpecificationId }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}
