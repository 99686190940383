import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Space, Typography } from 'antd';
import {
  ulBeneficiaryValidationSchema,
  ipBeneficiaryValidationSchema,
  IpBeneficiary,
  UlBeneficiary,
  ulInitialValues,
  ipInitialValues,
} from '../form';
import { InputRow } from '../fields/InputRow';
import { SelectRow } from '../fields/SelectRow';
import { BeneficiaryType, Citizenship } from '../../../constants/tinkoff';

import './BeneficiaryStep.scss';

const { Title } = Typography;

interface Props {
  onSubmit: (values: any, formikBag: FormikHelpers<any>) => void;
  initialValues: UlBeneficiary | IpBeneficiary;
  onBack: (values: any) => void;
}

export const BeneficiaryStep = ({ onSubmit, initialValues, onBack }: Props) => {
  const [type, setType] = useState<BeneficiaryType>(initialValues.type ?? BeneficiaryType.UlResident);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        type === BeneficiaryType.UlResident
          ? ulBeneficiaryValidationSchema
          : ipBeneficiaryValidationSchema
      }
      onSubmit={onSubmit}
    >
      {({ isSubmitting, resetForm, values }) => (
        <Form>
          <Title level={1}>Заявка на регистрацию</Title>
          <p>На этой странице вы можете заполнить заявку на присоединение к системе ClickPass.</p>
          <table style={{ maxWidth: '700px', width: '100%' }}>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <Title level={3}>Информация о бенифициаре</Title>
                  <p>
                    Заметка о бенифицаре
                  </p>
                </td>
              </tr>
              <SelectRow
                className="BeneficiaryStep-typeSelect"
                label="Тип"
                name="type"
                options={[{
                  label: 'Юридическое лицо',
                  value: BeneficiaryType.UlResident,
                }, {
                  label: 'Индивидуальный предприниматель',
                  value: BeneficiaryType.IpResident,
                }]}
                onChange={(value) => {
                  setType(value);
                  if (value === BeneficiaryType.UlResident) {
                    resetForm({ values: ulInitialValues });
                  } else {
                    resetForm({ values: ipInitialValues });
                  }
                }}
              />
              {
                type === BeneficiaryType.UlResident
                && (
                  <>
                    <InputRow label="Полное название" name="name" />
                    <InputRow label="Телефон" name="phoneNumber" />
                    <InputRow label="Электронная почта" name="email" />
                    <InputRow label="Адрес юридического лица" name="addresses.0.address" />
                    <InputRow label="Дата регистрации" name="registrationDate" />
                    <InputRow label="ИНН" name="inn" />
                    <InputRow label="ОГРН" name="ogrn" />
                  </>
                )
              }
              {
                type === BeneficiaryType.IpResident
                && (
                  <>
                    <tr>
                      <td colSpan={2}><Title level={3}>Информация о владельце ИП</Title></td>
                    </tr>
                    <InputRow label="Фамилия" name="lastName" />
                    <InputRow label="Имя" name="firstName" />
                    <InputRow label="Отчество" name="middleName" />
                    <InputRow label="Дата рождения" name="birthDate" />
                    <InputRow label="Место рождения" name="birthPlace" />
                    <SelectRow
                      label="Гражданство"
                      name="citizenship"
                      options={[{
                        label: 'Россия',
                        value: Citizenship.Russia,
                      }]}
                    />
                    <InputRow label="Телефон" name="phoneNumber" />
                    <InputRow label="Электронная почта" name="email" />
                    <tr>
                      <td colSpan={2}><Title level={3}>Паспортные данные</Title></td>
                    </tr>
                    <InputRow label="Серия" name="documents.0.serial" />
                    <InputRow label="Номер" name="documents.0.number" />
                    <InputRow label="Дата выдачи" name="documents.0.date" />
                    <InputRow label="Код подразделения" name="documents.0.division" />
                    <tr>
                      <td colSpan={2}><Title level={3}>Информация об ИП</Title></td>
                    </tr>
                    <InputRow label="Адрес регистрации" name="addresses.0.address" />
                    <InputRow label="Дата регистрации" name="registrationDate" />
                    <InputRow label="ИНН" name="inn" />
                    <InputRow label="ОГРНИП" name="ogrn" />
                  </>
                )
              }
            </tbody>
          </table>
          <Space>
            <Button
              onClick={() => {
                onBack(values);
              }}
            >
              Назад
            </Button>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Далее
            </Button>
          </Space>
        </Form>
      )}
    </Formik>
  );
};

export default BeneficiaryStep;
