import React, { forwardRef, useCallback } from 'react';
import { Checkbox as _Checkbox, CheckboxRef } from 'antd';
import { FieldProps } from 'formik';
import type { CheckboxProps as _CheckboxProps, CheckboxGroupProps as _CheckboxGroupProps } from 'antd/lib/checkbox';

import Field from './Field';
import { FormikFieldProps } from './FieldProps';
import { FormItemProps, makeField, memoField } from './makeField';

export { _CheckboxProps as CheckboxProps };
export { _CheckboxGroupProps as CheckboxGroupProps };

type CheckboxProps = FieldProps & _CheckboxProps;
type CheckboxGroupProps = FieldProps & _CheckboxGroupProps;

type CheckboxFieldProps = FormikFieldProps & _CheckboxProps;
type CheckboxGroupFieldProps = FormikFieldProps & _CheckboxGroupProps;

const CheckboxInternal = forwardRef((
  {
    field,
    form,
    meta,
    onChange: _onChange,
    ...restProps
  }: CheckboxProps,
  ref: React.Ref<CheckboxRef>,
) => {
  const { value, name, onChange } = field;
  return (
    <_Checkbox
      ref={ref}
      name={name}
      checked={value}
      onChange={useCallback<NonNullable<_CheckboxProps['onChange']>>((event) => {
        onChange(event);
        if (_onChange) {
          _onChange(event);
        }
      }, [_onChange])}
      {...restProps}
    />
  );
});

CheckboxInternal.displayName = 'CheckboxInternal';

const CheckboxInternalMemo = memoField(CheckboxInternal);

const CheckboxFieldInternal = forwardRef((
  {
    name,
    validate,
    fast,
    ...restProps
  }: CheckboxFieldProps,
  ref: React.Ref<HTMLInputElement>,
) => (
  <Field name={name} validate={validate} fast={fast}>
    {(fieldProps: any) => (
      <CheckboxInternalMemo ref={ref} {...fieldProps} {...restProps} />
    )}
  </Field>
));

CheckboxFieldInternal.displayName = 'CheckboxFieldInternal';

const CheckBoxGroup = forwardRef((
  {
    field,
    meta,
    onChange,
    form,
    ...restProps
  }: CheckboxGroupProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { value, name } = field;
  const { setFieldValue, setFieldTouched } = form;
  return (
    <_Checkbox.Group
      ref={ref}
      value={value}
      onChange={useCallback<NonNullable<_CheckboxGroupProps['onChange']>>((changedValue) => {
        setFieldValue(name, changedValue);
        setFieldTouched(name, true, false);
        if (onChange) {
          onChange(changedValue);
        }
      }, [onChange])}
      {...restProps}
    />
  );
});

CheckBoxGroup.displayName = 'CheckBoxGroup';

const CheckBoxGroupMemo = memoField(CheckBoxGroup);

const CheckBoxGroupField = forwardRef((
  {
    name,
    validate,
    fast,
    ...restProps
  }: CheckboxGroupFieldProps,
  ref: React.Ref<HTMLDivElement>,
) => (
  <Field name={name} validate={validate} fast={fast}>
    {(fieldProps: any) => (
      <CheckBoxGroupMemo ref={ref} {...fieldProps} {...restProps} />
    )}
  </Field>
));

CheckBoxGroupField.displayName = 'CheckBoxGroupField';

export interface CheckBoxComponent
  // eslint-disable-next-line max-len
  extends React.MemoExoticComponent<React.ForwardRefExoticComponent<CheckboxProps & React.RefAttributes<CheckboxRef>>> {
  Group: typeof CheckBoxGroupMemo;
}

export const Checkbox = CheckboxInternalMemo as CheckBoxComponent;
Checkbox.Group = CheckBoxGroupMemo;

export interface CheckBoxFieldComponent
  extends React.ForwardRefExoticComponent<CheckboxFieldProps & React.RefAttributes<CheckboxRef>> {
  Group: typeof CheckBoxGroupField;
}

export const CheckboxField = CheckboxFieldInternal as CheckBoxFieldComponent;
CheckboxField.Group = CheckBoxGroupField;

// eslint-disable-next-line max-len
type CheckBoxGroupWrapperComponent = React.ForwardRefExoticComponent<FormItemProps & CheckboxGroupFieldProps & React.RefAttributes<HTMLDivElement>>;

interface CheckboxWrapperComponent
  extends React.ForwardRefExoticComponent<FormItemProps & CheckboxFieldProps & React.RefAttributes<CheckboxRef>> {
  Group: CheckBoxGroupWrapperComponent;
}

export const CheckboxWrapper = makeField<_CheckboxProps>(CheckboxField) as CheckboxWrapperComponent;
CheckboxWrapper.Group = makeField<_CheckboxGroupProps>(CheckBoxGroupField) as CheckBoxGroupWrapperComponent;
