import React, { useCallback } from 'react';
import { useMount } from 'react-use';
import { Helmet } from 'react-helmet';
import { Table, Typography } from 'antd';
import type { TablePaginationConfig } from 'antd/lib/table';
import dayjs from 'dayjs';

import './ChildPartners.scss';
import { ChildAccount } from '../../api';
import { ChildPartnersContextState } from './context/childPartnersReducer';

interface Props {
  onFetch: () => void;
  accounts: ChildAccount[];
  pagination: ChildPartnersContextState['pagination'];
  onSetPagination: (pagination: ChildPartnersContextState['pagination']) => void;
  loading: boolean;
}

export const ChildPartners = ({
  onFetch,
  accounts,
  pagination,
  onSetPagination,
  loading,
}: Props) => {
  useMount(() => {
    onFetch();
  });
  const handleTableChange = useCallback((updatePagination: TablePaginationConfig) => {
    onSetPagination(updatePagination as ChildPartnersContextState['pagination']);
    onFetch();
  }, [onFetch, onSetPagination]);
  return (
    <div>
      <Helmet>
        <title>Партнеры</title>
      </Helmet>
      <div className="ChildPartners">
        <div className="ChildPartners-header">
          <Typography.Title level={3} className="ChildPartners-headerTitle">Партнеры</Typography.Title>
        </div>
        <Table
          loading={loading}
          columns={[{
            title: 'Название',
            dataIndex: 'name',
          }, {
            title: 'ИНН',
            dataIndex: ['tinkoffShop', 'info', 'inn'],
          }, {
            title: 'Дата Создания',
            dataIndex: ['tinkoffShop', 'created'],
            render: (date) => (date ? dayjs(date).format('DD.MM.YYYY') : ''),
          }]}
          dataSource={accounts}
          pagination={pagination}
          onChange={handleTableChange}
          rowKey="id"
        />
      </div>
    </div>
  );
};

export default ChildPartners;
