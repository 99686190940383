import React, { SetStateAction, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Space, Table, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import './ReportsPage.scss';

import ApplicationLayout from '../application/layouts/application-layout/ApplicationLayout';

import { FileMetadata, ReportTypeLabel } from './types';
import { formatDate, mapMimeTypeToExtension } from './utils';

type ReportsPageDependencies = {
  onLoadReports: () => Promise<any[]>
  onDownloadReport: (fileId: string, fileName: string) => Promise<void>
}

const ReportsPage = ({ onLoadReports, onDownloadReport }: ReportsPageDependencies) => {
  const [ reports, setReports ]: [ any[], SetStateAction<any> ] = useState([]);

  // @ts-ignore
  useEffect(async () => {
    const newReports = await onLoadReports();
    newReports.sort((a, b) => {
      if (a.data.year > b.data.year) {
        return -1;
      }
      if (b.data.year > a.data.year) {
        return 1;
      }
      if (a.data.month > b.data.month) {
        return -1;
      }
      if (b.data.month > a.data.month) {
        return 1;
      }
      return 1;
    });
    setReports(newReports);
  }, []);

  const columns = [
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      width: '200px',
      render: (created: string) => (formatDate(created)),
    }, {
      title: 'Тип отчета',
      dataIndex: [ 'type' ],
      render: (type: keyof typeof ReportTypeLabel) => ReportTypeLabel[type],
    }, {
      title: 'Год',
      dataIndex: [ 'data', 'year' ],
    }, {
      title: 'Месяц',
      dataIndex: [ 'data', 'month' ],
      render: (month: number) => dayjs().month(month - 1).format('MMMM'),
    },
    {
      dataIndex: [ 'data', 'files' ],
      width: '80px',
      render: (files: FileMetadata[]) => (
        <Space>
          {files.map((file) => (
            <Button
              id={file.id}
              icon={<DownloadOutlined />}
              onClick={() => {
                onDownloadReport(file.id, file.name);
              }}
            >
              {mapMimeTypeToExtension(file.mimeType)}
            </Button>
          ))}
        </Space>
      ),
    },
  ];

  return (
    <ApplicationLayout>
      <Helmet>
        <title>Отчеты по расписанию</title>
      </Helmet>
      <div className="ReportsPage">
        <div className="ReportsPage-header">
          <Typography.Title level={3} className="ReportsPage-header-title">Отчеты по расписанию</Typography.Title>
        </div>
        <Table
          columns={columns}
          dataSource={reports}
          pagination={false}
          rowKey="id"
        />
      </div>
    </ApplicationLayout>
  );
};

export default ReportsPage;
