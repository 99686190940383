import React, { Dispatch, useCallback, useRef } from 'react';
import { thunk } from 'redux-thunk';
import { createReducer, useMount, useUnmount } from 'react-use';
import { container } from 'ioc-container/container';
import { Providers } from 'ioc-container/providers';
import { AuthEventType } from 'services/ComponentNotificationService';

import authReducer, { AuthContextState, initialState } from './authReducer';
import { checkUser, processUnauthorizedRequest } from './authThunks';

const useThunkReducer = createReducer<any, AuthContextState>(thunk as any);

const AuthStateContext = React.createContext<AuthContextState>(initialState());
export const AuthDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode,
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useThunkReducer(authReducer, initialState());
  const onUnauthorizedRequest = useCallback(() => {
    dispatch(processUnauthorizedRequest);
  }, [dispatch]);
  const checkUserIntervalIdRef = useRef<ReturnType<typeof setInterval>>();

  useMount(() => {
    checkUserIntervalIdRef.current = setInterval(() => {
      dispatch(checkUser);
    }, 30000);
    container.resolve(Providers.componentNotificationService).on(
      AuthEventType.UnauthorizedRequest,
      onUnauthorizedRequest,
    );
  });

  useUnmount(() => {
    if (checkUserIntervalIdRef.current) {
      clearInterval(checkUserIntervalIdRef.current);
    }
    container.resolve(Providers.componentNotificationService).off(
      AuthEventType.UnauthorizedRequest,
      onUnauthorizedRequest,
    );
  });

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthStateContext');
  }
  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthDispatchContext');
  }
  return context;
}

export {
  AuthProvider,
  useAuthState,
  useAuthDispatch,
};
