import React from 'react';
import { Space, Button, Badge, Popover } from 'antd';
import { LayoutOutlined } from '@ant-design/icons';

import './Header.scss';
import { AuthenticationUser } from '../../../api/authentication/authenticationTypes';

interface Props {
  onSignOut: () => void;
  user: AuthenticationUser;
  sessionUser: AuthenticationUser;
  onSiderModeClick: () => void;
}

const Header = ({ user, onSignOut, sessionUser, onSiderModeClick }: Props) => {
  const loginAsOtherUser = sessionUser?.id ? sessionUser.id !== user?.id : false;
  return (
    <div className="Header">
      <Space className="Header-title" align="baseline">
        <Button type="text" icon={<LayoutOutlined />} onClick={onSiderModeClick} />
        <b>ClickPass</b>
      </Space>
      {
        user.id
        && (
          <span className="Header-user">
            {
              loginAsOtherUser
              && (
                <Popover
                  content={(
                    <div>
                      <div><b>Оригинальный пользователь:</b></div>
                      {
                        sessionUser.email
                      }
                    </div>
                  )}
                >
                  <Badge dot>
                    <b>{user.email}</b>
                  </Badge>
                </Popover>
              )
            }
            {
              !loginAsOtherUser && <b>{user.email}</b>
            }
            &nbsp;&nbsp;
            <small>{user.accountId}</small>
            &nbsp;&nbsp;
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a role="presentation" className="Header-signOut" onClick={onSignOut}>Выйти</a>
          </span>
        )
      }
    </div>
  );
};

export default Header;
