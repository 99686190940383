import { process } from '../env';
import { commonFetch } from './apiHelper';

export async function fetchReports() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/reports`;
  const response = await commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export async function downloadReport(reportId: string) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/reports/${reportId}/download`;
  const response = await commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const mimeType = response.headers.get('content-type');
  const content = await response.blob();

  return { content, mimeType };
}
