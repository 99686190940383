export const PublicRoutes = {
  SignUp: '/signup',
  Restore: '/restore',
};

export const PrivateRoutes = {
  Products: '/products',
  Orders: '/orders',
  Purchases: '/purchases',
  Scanner: '/scanner',
  Partners: '/partners/:tab',
  LoginAs: '/login-as',
  ChildPartners: '/child-partners',
  Inspectors: '/inspectors',
  Account: '/account/:tab',
  Reports: '/reports',
  Balance: '/balance',
  BalanceInfo: '/balance/balance',
  BalanceBankDetails: '/balance/bank-details',
};
