import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { checkProductItem, consumeProductItem } from '../../actions/productsActions';

import ScannerPage from './ScannerPage';
import { useAuthState } from '../authentication/context/authContext';
import { currentUserSelector } from '../authentication/context/authSelectors';

export const ScannerPageContainer = () => {
  const state = useAuthState();
  const dispatch = useDispatch();
  const { accountId } = currentUserSelector(state)!;
  return (
    <ScannerPage
      accountId={accountId}
      onRemoteProductItemCheck={useCallback((productItem: any) => {
        return new Promise((resolve, reject) => {
          dispatch(checkProductItem(productItem, resolve as any, reject));
        });
      }, [])}
      onRemoteProductItemConsume={useCallback((productItemId: string, productSpecificationId: string) => {
        return new Promise((resolve, reject) => {
          dispatch(consumeProductItem(productItemId, productSpecificationId, resolve as any, reject));
        });
      }, [])}
    />
  );
};

export default ScannerPageContainer;
