import { produce } from 'immer';
import type { AuthenticationUser } from '../../../api/authentication/authenticationTypes';
import { Role } from '../../../constants/rbac';

export interface AuthContextState {
  isAuthenticating: boolean,
  isAuthenticated: boolean,
  sessionUser?: AuthenticationUser,
  currentUser?: AuthenticationUser,
  roles: Record<Role, boolean>;
}

export const initialState = (): AuthContextState => ({
  isAuthenticating: true,
  isAuthenticated: false,
  sessionUser: undefined,
  roles: {
    [Role.Organizer]: false,
    [Role.Administrator]: false,
    [Role.Distributor]: false,
    [Role.Inspector]: false,
    [Role.MultiticketManager]: false,
  },
});

export const STORE_USER_ROLES = 'STORE_USER_ROLES';
export const setUserRoles = (roles: Record<Role, boolean>) => ({
  type: STORE_USER_ROLES, payload: { roles },
});

export const SET_AUTHENTICATING = 'SET_AUTHENTICATING';
export const setAuthenticating = (authenticating: boolean) => ({
  type: SET_AUTHENTICATING, payload: { authenticating },
});

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const setAuthenticated = (authenticated: boolean) => ({
  type: SET_AUTHENTICATED, payload: { authenticated },
});

export const SET_SESSION_USER = 'SET_SESSION_USER';
export const setSessionUser = (user?: AuthenticationUser) => ({
  type: SET_SESSION_USER, payload: { user },
});

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const setCurrentUser = (user?: AuthenticationUser) => ({
  type: SET_CURRENT_USER, payload: { user },
});

const authReducer = (state: AuthContextState, action: any) => produce(state, (draftState) => {
  switch (action.type) {
    case SET_SESSION_USER: {
      const { user } = (action as ReturnType<typeof setSessionUser>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.sessionUser = user;
      break;
    }
    case SET_CURRENT_USER: {
      const { user } = (action as ReturnType<typeof setCurrentUser>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.currentUser = user;
      break;
    }
    case STORE_USER_ROLES: {
      const { roles } = (action as ReturnType<typeof setUserRoles>).payload;
      console.log('saving', roles);
      // eslint-disable-next-line no-param-reassign
      draftState.roles = roles;
      break;
    }
    case SET_AUTHENTICATING: {
      // eslint-disable-next-line no-param-reassign
      draftState.isAuthenticating = action.payload.authenticating;
      break;
    }
    case SET_AUTHENTICATED: {
      // eslint-disable-next-line no-param-reassign
      draftState.isAuthenticated = action.payload.authenticated;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default authReducer;
