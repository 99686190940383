export const OrderStatus = {
  New: 'NEW',
  Canceled: 'CANCELED',
  Authorized: 'AUTHORIZED',
  Confirmed: 'CONFIRMED',
  Refunded: 'REFUNDED',
  Reversed: 'REVERSED',
  PartialRefunded: 'PARTIAL_REFUNDED',
  DeadlineExpired: 'DEADLINE_EXPIRED',
};

export const OrderStatusText = {
  [OrderStatus.New]: 'Создан',
  [OrderStatus.Authorized]: 'Авторизован',
  [OrderStatus.Canceled]: 'Отменён',
  [OrderStatus.Confirmed]: 'Подтверждён',
  [OrderStatus.PartialRefunded]: 'Возвращен част.',
  [OrderStatus.Refunded]: 'Возвращен полн.',
  [OrderStatus.Reversed]: 'Холдирование отменено',
  [OrderStatus.DeadlineExpired]: 'Время оплаты истекло',
};