import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import {
  InputWrapper,
  TextAreaWrapper,
  SelectWrapper,
  RadioWrapper,
} from 'utils/form-helpers/antd-formik';

import { Modal, Form, Divider } from 'antd';
import { Formik, FormikHelpers } from 'formik';

import './BaseCalendarProductFormModal.scss';
import Label from './label/Label';
import { getEventDefaultValue, Schedule } from './schedule/Schedule';
import Categories, { getDefaultCategory } from './categories/Categories';
import Duration from './duration/Duration';
import { LocationSelect } from './location-select/LocationSelect';
import type { Location } from '../../../../api/location';
import Photos from './photos/Photos';
import { IntegrationMode } from '../../../../constants/schema';

export enum ScheduleType {
  TimeExact = 'timeExact',
  TimePeriod = 'timePeriod',
}

const categoriesOptions = [
  { label: 'Экскурсии', value: 'excursion' },
  { label: 'Отели, гостиницы', value: 'hotel' },
  { label: 'Мастер-классы', value: 'masterClass' },
  { label: 'Прокат', value: 'rental' },
  { label: 'Входные билеты', value: 'entryTicket' },
  { label: 'Дегустации', value: 'tasting' },
  { label: 'На воде', value: 'onTheWater' },
  { label: 'Wellness и SPA-программы', value: 'wellnessSpa' },
  { label: 'Еда и напитки', value: 'foodAndDrink' },
  { label: 'Загородный отдых и природа', value: 'countryRestAndNature' },
  { label: 'Для пар', value: 'forCouples' },
  { label: 'Для всей семьи', value: 'forWholeFamily' },
  { label: 'Для групп', value: 'forGroups' },
  { label: 'Культура и история', value: 'cultureAndHistory' },
  { label: 'Религиозные и духовные мероприятия', value: 'religiousEvent' },
  { label: 'Музыка', value: 'music' },
  { label: 'Спорт и приключения', value: 'sportsAndAdventure' },
  { label: 'Дикая природа', value: 'wildNature' },
  { label: 'Праздничные мероприятия', value: 'festiveEvent' },
  { label: 'Снег и зимние виды спорта', value: 'snowAndWinterSports' },
  { label: 'Музеи и выставки', value: 'museumAndExhibition' },
  { label: 'Национальные парки', value: 'nationalPark' },
  { label: 'Аттракционы', value: 'attraction' },
  { label: 'Зоопарки и аквариумы', value: 'zooAndAquarium' },
  { label: 'Смотровые площадки', value: 'observationDecks' },
  { label: 'Авиа', value: 'avia' },
  { label: 'Круизы', value: 'cruise' },
  { label: 'Достопримечательности', value: 'landmark' },
  { label: 'Театр', value: 'theater' },
  { label: 'Бегом по золотому кольцу', value: 'goldenRingRunning' },
  { label: 'Другое', value: 'other' },
];

export enum ProgramType {
  Individual = 'individual',
  Group = 'group'
}

const ageOptions = new Array(19)
  .fill(0)
  .map((zero, index) => {
    const age = zero + index;
    return {
      key: age, value: age, label: `${age}+`,
    };
  });

interface Props {
  open?: boolean;
  onCancel: () => void;
  productGroups: any;
  initLocation?: Location;
  product: any;
  onSubmit: (values: any, formikBag: FormikHelpers<any>) => void;
  initialValues: any,
}

// eslint-disable-next-line react/prefer-stateless-function
export class BaseCalendarProductFormModal extends React.Component<Props> {
  render() {
    const { open, onCancel, productGroups, product, onSubmit, initialValues, initLocation } = this.props;
    const title = product ? 'Обновить товар' : 'Добавить товар';
    const okText = product ? 'Обновить' : 'Добавить';

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            maskClosable={false}
            width={1000}
            open={open}
            title={title}
            onCancel={onCancel}
            okText={okText}
            onOk={() => handleSubmit()}
            destroyOnClose
          >
            <div className="BaseCalendarProductFormModal">
              <Form
                layout="vertical"
              >
                <SelectWrapper
                  style={{ width: '200px' }}
                  name="productGroupId"
                  antFieldProps={{ label: <Label title="Группа товара" /> }}
                  defaultValue={null}
                >
                  <SelectWrapper.Option value={null}>Не выбрана</SelectWrapper.Option>
                  {productGroups.map((productGroup: any) => (
                    <SelectWrapper.Option
                      key={productGroup.id}
                      value={productGroup.id}
                    >
                      {productGroup.name}
                    </SelectWrapper.Option>
                  ))}
                </SelectWrapper>
                <RadioWrapper.Group
                  name="integrationMode"
                  antFieldProps={{ label: <Label title="Интеграция с маркетплейсом" /> }}
                  options={[{
                    value: IntegrationMode.HideOnMarketplace,
                    label: 'Скрыть везде',
                  }, {
                    value: IntegrationMode.ShowOnlyOnPartnerMarketplace,
                    label: 'Показать на своем маркетплейсе',
                  }, {
                    value: IntegrationMode.ShowEverywhere,
                    label: 'Показать везде',
                  }]}
                />
                <Divider />
                <InputWrapper
                  name="specification.title"
                  antFieldProps={{
                    label: (
                      <Label
                        title="Название"
                        description="Короткое название отображается как главный заголовок"
                      />
                    ),
                  }}
                />
                <Divider />
                <TextAreaWrapper
                  name="specification.description"
                  antFieldProps={{
                    label: (
                      <Label
                        title="Описание"
                        description="Расскажите о своей экскурсии, прогулке, мастер-классе или туре"
                      />
                    ),
                  }}
                  rows={5}
                />
                <Divider />
                <TextAreaWrapper
                  name="specification.important"
                  antFieldProps={{
                    label: (
                      <Label
                        title="Важно знать"
                        description={
                          `Напишите, о чем стоит знать путешественникам перед
                          экскурсией. Например, о дополнительных расходах, деталях
                          трансфера, о том, что взять с собой, и других важных моментах.  
                          Либо напишите, что таких важных моментов нет.`
                        }
                      />
                    ),
                  }}
                  rows={5}
                />
                <TextAreaWrapper
                  name="specification.included"
                  antFieldProps={{ label: <Label title="Что включено" /> }}
                  rows={3}
                />
                <TextAreaWrapper
                  name="specification.notIncluded"
                  antFieldProps={{ label: <Label title="Не входит" /> }}
                  rows={3}
                />
                <LocationSelect
                  initLocation={initLocation}
                  name="specification.locationId"
                  antFieldProps={{ label: <Label description="Город начала (проведения) программы" /> }}
                />
                <InputWrapper
                  name="specification.address"
                  antFieldProps={{ label: <Label description="Адрес или место встречи / начала" /> }}
                />
                <Label
                  className="BaseCalendarProductFormModal-durationLabel"
                  description="Продолжительность мероприятия"
                />
                <Duration
                  name="specification.duration"
                />
                <SelectWrapper
                  style={{ width: '200px' }}
                  name="specification.ageLimit"
                  antFieldProps={{ label: <Label description="Возрастные ограничения" /> }}
                >
                  {
                    ageOptions.map((option) => (
                      <SelectWrapper.Option
                        key={option.key}
                        value={option.value}
                      >
                        {option.label}
                      </SelectWrapper.Option>
                    ))
                  }
                </SelectWrapper>
                <SelectWrapper
                  optionFilterProp="label"
                  mode="multiple"
                  style={{ width: '500px' }}
                  name="specification.productCategory"
                  antFieldProps={{ label: <Label description="Категория продукта" /> }}
                >
                  {
                    categoriesOptions.map((option) => (
                      <SelectWrapper.Option
                        key={option.value}
                        value={option.value}
                        label={option.label}
                      >
                        {option.label}
                      </SelectWrapper.Option>
                    ))
                  }
                </SelectWrapper>
                <Divider />
                <RadioWrapper.Group
                  name="specification.programType"
                  antFieldProps={{ label: <Label title="Тип программы" /> }}
                  options={[{
                    label: 'Индивидуальный', value: ProgramType.Individual,
                  }, {
                    label: 'Групповой', value: ProgramType.Group,
                  }]}
                  onChange={(event) => {
                    setFieldValue('specification.categories', [getDefaultCategory(event.target.value)]);
                  }}
                />
                <Label className="BaseCalendarProductFormModal-categoryLabel" title="Стоимость и категории билетов" />
                <Categories />
                <Label
                  className="BaseCalendarProductFormModal-durationLabel"
                  title="Закрывать продажи за"
                />
                <Duration
                  name="specification.closeSalesIn"
                />
                <RadioWrapper.Group
                  name="specification.scheduleType"
                  antFieldProps={{ label: <Label title="Расписание" /> }}
                  onChange={(e) => {
                    const eventDefaultValue = getEventDefaultValue(e.target.value);
                    setFieldValue('specification.schedule', [{
                      date: dayjs(),
                      events: [eventDefaultValue],
                    }]);
                  }}
                  options={[{
                    label: 'Время начала мероприятия', value: ScheduleType.TimeExact,
                  }, {
                    label: 'Период (посещение ограничено временным интервалом)', value: ScheduleType.TimePeriod,
                  }]}
                />
                <Schedule />
                <Label title="Фотографии" className="BaseCalendarProductFormModal-photosLabel" />
                <Photos name="specification.photos" />
                {errors?.summary && <div className="BaseCalendarProductFormModal-error">{errors.summary as any}</div>}
              </Form>
            </div>
          </Modal>
        )}
      </Formik>
    );
  }
}

export default BaseCalendarProductFormModal;
