import React from 'react';
import { Typography } from 'antd';
import { SelectRow } from '../fields/SelectRow';

const { Title } = Typography;

interface Props {
  disabled?: boolean;
}

export const ParentAccountInfo = ({ disabled }: Props) => (
  <table style={{ maxWidth: '700px', width: '100%' }}>
    <tbody>
      <tr>
        <td colSpan={4}>
          <Title level={3}>Родительский аккаунт</Title>
          <p>Организация будет закреплена за родительским аккаунтом.</p>
        </td>
      </tr>
      <SelectRow
        allowClear
        style={{ width: '200px' }}
        disabled={disabled}
        label="Аккаунт"
        name="parentAccount"
        options={[{
          label: 'МАУ ГОГР «ТИЦ» (г. Рыбинск)',
          value: '6fd3e64a-1822-4956-9283-28bfe66c8a29',
        }, {
          label: 'ООО «АСТ» (г. Анапа)',
          value: '4077ca8d-1dbc-4b69-a211-39c9fdfcd965',
        }]}
      />
    </tbody>
  </table>
);

export default ParentAccountInfo;
