import { process } from '../env';
import { commonFetch } from './apiHelper';

export function fetchInspectors() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/inspector`;
  return commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}

export function createInspector(inspector: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/inspector `;
  return commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(inspector),
  })
    .then((response) => response.json());
}

export function updateInspector(id: string, inspector: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/inspector/${id}`;
  return commonFetch(requestURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(inspector),
  })
    .then((response) => response.json());
}

export function deleteInspector(id: string) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/inspector/${id}`;
  return commonFetch(requestURL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}
