import dayjs from 'dayjs';

export const mapMimeTypeToExtension = (mimeType: string): string => {
  switch (mimeType) {
    case 'text/csv':
      return 'CSV';
    case 'application/pdf':
      return 'PDF';
    case 'application/vnd.ms-excel':
      return 'XLSX';
    default:
      throw new Error('Unknown file extension.');
  }
};

export const formatDate = (date: string | Date) => dayjs(date).format('DD.MM.YYYY HH:mm');
