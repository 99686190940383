import { process } from '../env';
import { commonFetch } from './apiHelper';

export function fetchRegistrationTasks() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/tasks`;
  return commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}

export function fetchRegistrationPayments() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/registration/payments`;
  return commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}
