import React, { useCallback } from 'react';

import Header from './Header';
import { useAuthDispatch, useAuthState } from '../../authentication/context/authContext';
import { currentUserSelector, sessionUserSelector } from '../../authentication/context/authSelectors';
import { signOut } from '../../authentication/context/authThunks';

interface Props {
  onSiderModeClick: () => void;
}

export const HeaderContainer = ({ onSiderModeClick }: Props) => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const user = currentUserSelector(state)!;
  const sessionUser = sessionUserSelector(state)!;
  return (
    <Header
      onSignOut={useCallback(() => {
        dispatch(signOut);
      }, [dispatch])}
      user={user}
      sessionUser={sessionUser}
      onSiderModeClick={onSiderModeClick}
    />
  );
};
