import React, { useCallback } from 'react';
import { ApplicationLayout } from '../application/layouts/application-layout/ApplicationLayout';
import ChildPartners from './ChildPartners';
import { ChildPartnersProvider, useChildPartnersDispatch, useChildPartnersState } from './context/childPartnersContext';
import { listItemSelector, listLoadingSelector, paginationSelector } from './context/childPartnersSelectors';
import { getList } from './context/childPartnersThunks';
import {
  ChildPartnersContextState,
  storePagination,
} from './context/childPartnersReducer';

export const ChildPartnersContainer = () => {
  const dispatch = useChildPartnersDispatch();
  const state = useChildPartnersState();
  return (
    <ApplicationLayout>
      <ChildPartners
        onFetch={useCallback(() => {
          dispatch(getList);
        }, [dispatch])}
        onSetPagination={useCallback((pagination: ChildPartnersContextState['pagination']) => {
          dispatch(storePagination(pagination));
        }, [dispatch])}
        loading={listLoadingSelector(state)}
        accounts={listItemSelector(state)}
        pagination={paginationSelector(state)}
      />
    </ApplicationLayout>
  );
};

export const ChildPartnersWrapper = () => (
  <ChildPartnersProvider>
    <ChildPartnersContainer />
  </ChildPartnersProvider>
);

export default ChildPartnersWrapper;
