import { process } from '../env';
import { commonFetch } from './apiHelper';

export function createProductGroup(productGroup: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-groups`;
  return commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(productGroup),
  })
    .then((response) => response.json());
}

export function updateProductGroup(id: string, productGroup: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-groups/${id}`;
  return commonFetch(requestURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(productGroup),
  })
    .then((response) => response.json());
}

export function deleteProductGroup(id: string) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-groups/${id}`;
  return commonFetch(requestURL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}

export function fetchProductGroups() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-groups`;
  return commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}
