import { Dispatch } from 'react';
import dayjs from 'dayjs';
import { produce } from 'immer';

import {
  OrderSummaryListContextState, setOrderSummaryListLoading,
  storeListItems, storePagination,
} from './orderSummaryListReducer';
import { filterSelector, paginationSelector, sortingSelector, accountIdSelector } from './orderSummaryListSelectors';
import { getOrderList, getOrderListCsv } from '../../../../api/orders';
import { saveFile } from '../../../../utils/fileDownload';

export const getList = async (dispatch: Dispatch<any>, getState: () => OrderSummaryListContextState) => {
  const state = getState();
  const filter = filterSelector(state);
  const sorting = sortingSelector(state);
  const accountId = accountIdSelector(state);
  const pagination = paginationSelector(state);
  dispatch(setOrderSummaryListLoading(true));
  const apiFilter = produce(filter, (draft) => {
    if (draft.dates) {
      // eslint-disable-next-line no-param-reassign
      draft.dates[0] = dayjs(draft.dates?.[0]).startOf('day');
      // eslint-disable-next-line no-param-reassign
      draft.dates[1] = dayjs(draft.dates?.[1]).endOf('day');
    }
  });

  try {
    const result = await getOrderList(accountId, apiFilter, pagination, sorting);
    if (!result) {
      throw new Error('No data');
    }
    dispatch(storePagination({
      ...pagination,
      total: result.total,
    }));
    dispatch(storeListItems(result.orders, result.productItems));
  } finally {
    dispatch(setOrderSummaryListLoading(false));
  }
};

export const getListCsv = async (dispatch: Dispatch<any>, getState: () => OrderSummaryListContextState) => {
  const state = getState();
  const filter = filterSelector(state);
  const sorting = sortingSelector(state);
  const accountId = accountIdSelector(state);
  // dispatch(setOrderSummaryListLoading(true));
  const apiFilter = produce(filter, (draft) => {
    if (draft.dates) {
      // eslint-disable-next-line no-param-reassign
      draft.dates[0] = dayjs(draft.dates?.[0]).startOf('day');
      // eslint-disable-next-line no-param-reassign
      draft.dates[1] = dayjs(draft.dates?.[1]).endOf('day');
    }
  });

  try {
    const result = await getOrderListCsv(accountId, apiFilter, sorting);
    saveFile(result, result.type, 'Распространитель-Продажи.csv');
  } finally {
    // dispatch(setOrderSummaryListLoading(false));
  }
};
