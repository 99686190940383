import React, { useState, useCallback, useEffect } from 'react';
import type { SelectProps } from 'antd';
import debounce from 'lodash.debounce';
import type { FormItemProps } from 'antd/lib/form/FormItem';

import { SelectWrapper } from '../../../../../utils/form-helpers/antd-formik';
import { searchLocation, Location } from '../../../../../api/location';

interface Props extends SelectProps<string> {
  name: string;
  antFieldProps?: FormItemProps;
  initLocation?: Location;
}

export const LocationSelect = ({ initLocation, ...other }: Props) => {
  const [options, setOptions] = useState<Location[]>([]);
  useEffect(() => {
    setOptions(initLocation ? [initLocation] : []);
  }, [initLocation]);
  return (
    <SelectWrapper
      {...other}
      showSearch
      filterOption={false}
      onSearch={debounce(useCallback(async (value: string) => {
        const locations = await searchLocation(value);
        setOptions(locations);
      }, []), 250)}
    >
      {
        options.map((option) => {
          const hasDot = option.type.slice(-1) === '.';
          // eslint-disable-next-line max-len
          const label = `${option.type}${hasDot ? '' : '.'} ${option.settlement} (${option.region}, ${option.municipality})`;
          return (
            <SelectWrapper.Option key={option.id} value={option.id}>
              {label}
            </SelectWrapper.Option>
          );
        })
      }
    </SelectWrapper>
  );
};

export default LocationSelect;
