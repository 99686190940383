import React from 'react';
import { Button } from 'antd';
import { ErrorMessage, useFormikContext } from 'formik';
import { MinusOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';

import { DatePickerWrapper, InputNumberWrapper, SelectWrapper } from '../../../../../utils/form-helpers/antd-formik';
import { ScheduleType } from '../BaseCalendarProductFormModal';

import './Schedule.scss';

const endDate = dayjs().endOf('day');

export const eventTimes: string[] = [];
let itTime = dayjs().startOf('day');
do {
  eventTimes.push(itTime.format('HH:mm'));
  itTime = itTime.add(15, 'minutes');
} while (itTime.isBefore(endDate));

export const getEventDefaultValue = (
  scheduleType: ScheduleType,
) => (
  scheduleType === ScheduleType.TimeExact
    ? {
      startTime: '08:00',
      quantity: 100,
    }
    : {
      startTime: '08:00',
      endTime: '09:00',
      quantity: 100,
    }
);

export const Schedule = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { scheduleType } = values.specification;
  return (
    <div className="Schedule">
      <span>Дата</span>
      <span>Время и количество билетов</span>
      {
        values.specification.schedule?.map((scheduleInfo: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <div className="Schedule-dateSection">
              <DatePickerWrapper
                className="Schedule-datePicker"
                name={`specification.schedule.${index}.date`}
                format="DD.MM.YYYY"
              />
              <Button
                className="Schedule-deleteItemButton"
                size="small"
                danger
                shape="circle"
                icon={<MinusOutlined />}
                onClick={() => {
                  values.specification.schedule.splice(index, 1);
                  setFieldValue(
                    'specification.schedule',
                    values.specification.schedule,
                  );
                }}
              />
            </div>
            <div className="Schedule-scheduleTimeSection">
              {
                scheduleInfo.events.map((_: any, eventIndex: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="Schedule-scheduleEventTimeItem" key={eventIndex}>
                    <SelectWrapper
                      className="Schedule-timeSelect"
                      name={`specification.schedule.${index}.events.${eventIndex}.startTime`}
                    >
                      {
                        eventTimes.map((time) => (
                          <SelectWrapper.Option key={time} value={time}>{time}</SelectWrapper.Option>
                        ))
                      }
                    </SelectWrapper>
                    {
                      values.specification.scheduleType === ScheduleType.TimePeriod
                      && (
                        <SelectWrapper
                          className="Schedule-timeSelect"
                          name={`specification.schedule.${index}.events.${eventIndex}.endTime`}
                        >
                          {
                            eventTimes.map((time) => (
                              <SelectWrapper.Option key={time} value={time}>{time}</SelectWrapper.Option>
                            ))
                          }
                        </SelectWrapper>
                      )
                    }
                    <InputNumberWrapper
                      name={`specification.schedule.${index}.events.${eventIndex}.quantity`}
                    />
                    <Button
                      className="Schedule-deleteItemButton"
                      size="small"
                      danger
                      shape="circle"
                      icon={<MinusOutlined />}
                      onClick={() => {
                        values.specification.schedule[index].events.splice(eventIndex, 1);
                        setFieldValue(
                          `specification.schedule.${index}.events`,
                          values.specification.schedule[index].events,
                        );
                      }}
                    />
                  </div>
                ))
              }
              <Button
                className="Schedule-addButton"
                type="text"
                onClick={() => {
                  const newIndex = values.specification.schedule[index].events.length;
                  const lastEvent = values.specification.schedule[index].events[newIndex - 1];
                  let newValue = getEventDefaultValue(scheduleType);
                  if (newIndex !== 0) {
                    if (scheduleType === ScheduleType.TimeExact) {
                      const lastTimeIndex = eventTimes.findIndex((time) => time === lastEvent.startTime);
                      const nextTime = eventTimes[lastTimeIndex + 1] ?? eventTimes[0];
                      newValue = {
                        startTime: nextTime,
                        quantity: lastEvent.quantity,
                      };
                    } else {
                      const lastEndTimeIndex = eventTimes.findIndex((time) => time === lastEvent.endTime);
                      const nextEndTime = eventTimes[lastEndTimeIndex + 1] ?? eventTimes[0];
                      newValue = {
                        startTime: lastEvent.endTime,
                        endTime: nextEndTime,
                        quantity: lastEvent.quantity,
                      };
                    }
                  }
                  setFieldValue(`specification.schedule.${index}.events.[${newIndex}]`, newValue);
                }}
              >
                Добавить время
              </Button>
              <ErrorMessage name={`specification.schedule.${index}.events`}>
                {(msg) => (
                  typeof msg === 'string'
                  && <div className="BaseCalendarProductFormModal-error">{msg}</div>
                )}
              </ErrorMessage>
            </div>
          </React.Fragment>
        ))
      }
      <Button
        className="Schedule-addButton Schedule-addDateButton"
        type="text"
        onClick={() => {
          const newIndex = values.specification.schedule.length;
          const lastDate = values.specification.schedule[newIndex - 1]?.date;
          const lastDateEvents = values.specification.schedule[newIndex - 1]?.events;
          setFieldValue(
            `specification.schedule.[${newIndex}]`,
            {
              date: lastDate ? dayjs(lastDate).add(1, 'day') : dayjs(),
              events: lastDateEvents ? [...lastDateEvents] : [getEventDefaultValue(scheduleType)],
            },
          );
        }}
      >
        Добавить Дату
      </Button>
      <ErrorMessage name="specification.schedule">
        {(msg) => (
          typeof msg === 'string'
          && <div className="BaseCalendarProductFormModal-error">{msg}</div>
        )}
      </ErrorMessage>
    </div>
  );
};

export default Schedule;
