import set from 'lodash.set';
import { FormikHelpers } from 'formik';
import { AnyObjectSchema, ValidationError } from 'yup';
import * as Yup from 'yup';

const yupErrorsToFormikFormErrors = (errors: ValidationError) => {
  const formErrors = {};
  const formTouched = {};
  if (errors.inner && errors.inner.length > 0) {
    errors.inner.forEach((error) => {
      if (error.path) {
        set(formErrors, error.path, error.message);
        set(formTouched, error.path, true);
      }
    });
  }
  return { formErrors, formTouched };
};

export const showErrors = <Values>(error: unknown, formikHelpers: FormikHelpers<Values>) => {
  if (error instanceof ValidationError) {
    const { formErrors, formTouched } = yupErrorsToFormikFormErrors(error);
    formikHelpers.setErrors(formErrors);
    formikHelpers.setTouched(formTouched, false);
    return;
  }
  throw error;
};

export const createValidationError = (errors: Array<{ path: string, message: string }>) => {
  const transformedError = errors.map((error) => (
    { errors: [], name: 'ValidationError', inner: [error] }
  ));
  return new Yup.ValidationError(transformedError as any);
};

export const submitValidate = <Values>(
  values: Values, schema: AnyObjectSchema,
  formikHelpers: FormikHelpers<Values>,
) => {
  try {
    schema.validateSync(values, { abortEarly: false });
    return true;
  } catch (error) {
    showErrors(error, formikHelpers);
  }
  return false;
};
