import { createSelector } from 'reselect';
import { AuthContextState } from './authReducer';
import { Role } from '../../../constants/rbac';

export const isAuthenticatedSelector = (state: AuthContextState) => state.isAuthenticated;
export const isAuthenticatingSelector = (state: AuthContextState) => state.isAuthenticating;
export const sessionUserSelector = (state: AuthContextState) => state.sessionUser;
export const currentUserSelector = (state: AuthContextState) => state.currentUser;
export const createIsUserRoleSelector = (role: Role) => createSelector(
  (state: AuthContextState) => state.roles,
  (roles) => {
    console.log(role, roles[role]);
    return roles[role];
  },
);
