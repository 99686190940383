// NOTICE: Don't change let to const to avoid embedding.
export let process = {
  env: {
    NODE_ENV: 'production',
    BACKEND_URL: 'https://api.clickpass.ru',
    INTEGRATOR_URL: 'https://pay.clickpass.ru'
  }
}

if (/^PROCESS_ENV_/.test(process.env.NODE_ENV)) {
  console.log('Using NODE_ENV from webpack.DefinePlugin.');
  // @ts-ignore
  process.env.NODE_ENV = BUILD_ENV_NODE_ENV;
}
if (/^PROCESS_ENV_/.test(process.env.BACKEND_URL)) {
  console.log('Using BACKEND_URL from webpack.DefinePlugin.');
  // @ts-ignore
  process.env.BACKEND_URL = BUILD_ENV_BACKEND_URL;
}
if (/^PROCESS_ENV_/.test(process.env.INTEGRATOR_URL)) {
  console.log('Using INTEGRATOR_URL from webpack.DefinePlugin.');
  // @ts-ignore
  process.env.INTEGRATOR_URL = BUILD_ENV_INTEGRATOR_URL;
}