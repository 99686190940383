import { process } from '../env';
import { commonFetch, Response } from './apiHelper';

export const getWorkflow = (workflowId: string): Promise<any> => {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${workflowId}`;
  return commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
};

export const updateWorkflow = (workflowId: string, data: any): Promise<any> => {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${workflowId}`;
  return commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json());
};

export const verifyWorkflow = (workflowId: string): Promise<any> => {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${workflowId}/verify`;
  return commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
};

export const denyWorkflow = (workflowId: string): Promise<any> => {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${workflowId}/deny`;
  return commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
};

export const registerBeneficiary = (workflowId: string): Promise<any> => {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/`
    + `${workflowId}/register-beneficiary`;
  return commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.token}`,
    },
  })
    .then((response) => response.json());
};

export const approveWorkflow = (workflowId: string): Promise<any> => {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${workflowId}/approve`;
  return commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
};

export function signUp(signUpData: any): Promise<Response<any>> {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration`;
  return commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(signUpData),
  })
    .then((response) => response.json());
}
