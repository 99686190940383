import React from 'react';
import * as Yup from 'yup';

import { Modal } from 'antd';
import { Formik, Form, ErrorMessage } from 'formik';

import './ProductGroupFormModal.scss';
import { InputField } from '../../../utils/form-helpers/antd-formik';

export class ProductGroupFormModal extends React.Component {
  constructor() {
    super();
    this.onFinish = this.onFinish.bind(this);
  }

  async onFinish(values, { setSubmitting, setErrors }) {
    const { productGroup, onCreateProductGroup, onUpdateProductGroup, onComplete } = this.props;
    try {
      if (productGroup) {
        await onUpdateProductGroup(productGroup.id, values);
      } else {
        await onCreateProductGroup(values);
      }
      onComplete();
    } catch (error) {
      setErrors(error);
      setSubmitting(false);
    }
  }

  validationSchema = Yup.object().shape({
    name: Yup.string().required('Введите название группы')
  });

  render() {
    const { open, onCancel } = this.props;
    const { productGroup } = this.props;
    const name = productGroup ? 'Обновить групу' : 'Добавить группу';
    const okText = productGroup ? 'Обновить' : 'Добавить';
    const defaultProductGroup = {
      name: 'Новая группа'
    }
    return (
      <Formik
        enableReinitialize={true}
        initialValues={productGroup || defaultProductGroup}
        validationSchema={this.validationSchema}
        onSubmit={this.onFinish}
      >
        {({
            errors,
            handleSubmit,
          }) => (
          <Modal
            open={open}
            title={name}
            onCancel={onCancel}
            okText={okText}
            onOk={handleSubmit}
            destroyOnClose={true}
          >
            <div className="ProductGroupFormModal">
              <Form>
                <table width="100%">
                  <tbody>
                  <tr>
                    <td>
                      Название:<br />
                      <InputField name="name" />
                      <ErrorMessage name={`name`}>
                        {(msg) => <div className="ProductGroupFormModal-error">{msg}</div>}
                      </ErrorMessage>
                    </td>
                  </tr>
                  </tbody>
                </table>
                {errors?.summary && <div className="ProductGroupFormModal-error">{errors.summary}</div>}
              </Form>
            </div>
          </Modal>
        )}
      </Formik>
    );
  }
}
