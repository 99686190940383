import React from 'react';
import {
  Switch as _Switch,
  SwitchProps as _SwitchProps,
} from 'antd';
import { FieldProps } from 'formik';

import Field from './Field';
import { FormikFieldProps } from './FieldProps';
import { makeField } from './makeField';

type SwitchProps = FieldProps & _SwitchProps;
type SwitchFieldProps = FormikFieldProps & _SwitchProps;

export const Switch = React.forwardRef((
  {
    field,
    form,
    onChange,
    ...restProps
  }: SwitchProps,
  ref: React.Ref<HTMLButtonElement>,
) => {
  const { value, name } = field;
  const { setFieldValue, setFieldTouched } = form;
  return (
    <_Switch
      ref={ref}
      checked={value}
      onChange={(checked, event) => {
        setFieldValue(name, checked);
        setFieldTouched(name, true, false);
        if (onChange) {
          onChange(checked, event);
        }
      }}
      {...restProps}
    />
  );
});

Switch.displayName = 'Switch';

export const SwitchField = React.forwardRef((
  {
    name,
    validate,
    fast,
    ...restProps
  }: SwitchFieldProps,
  ref: React.Ref<HTMLElement>,
) => (
  <Field name={name} validate={validate} fast={fast}>
    {(fieldProps: any) => (
      <Switch ref={ref} {...fieldProps} {...restProps} />
    )}
  </Field>
));

SwitchField.displayName = 'SwitchField';

export const SwitchWrapper = makeField<_SwitchProps>(Switch);
