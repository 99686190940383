export const saveFile = (blob: Blob, type?: string, fileName?: string) => {
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // @ts-ignore
    navigator.msSaveBlob(blob, fileName);
    return;
  }
  const csvData = new Blob([blob], { type });
  const fileUrl = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = fileUrl;
  if (fileName) {
    link.download = fileName;
  }

  link.click();
  link.remove();
};
