import React from 'react';
import { notification } from 'antd';

import ApproveTask from './ApproveTask';
import { approveWorkflow, denyWorkflow, getWorkflow, updateWorkflow } from '../../../../api/partnerRegistration';

type ApproveTaskContainerProps = {
  workflowId: string
}

const ApproveTaskContainer = ({ workflowId }: ApproveTaskContainerProps) => {
  return (
    <ApproveTask
      workflowId={workflowId}
      onLoad={async () => (
        getWorkflow(workflowId)
      )}
      onUpdate={async (data: any) => {
        try {
          await updateWorkflow(workflowId, data);
          return true;
        } catch (error: any) {
          notification.error({ message: error.message });
          return false;
        }
      }}
      onApprove={async () => {
        try {
          await approveWorkflow(workflowId);
          return true;
        } catch (error: any) {
          notification.error({ message: error.message });
          return false;
        }
      }}
      onDeny={async () => {
        try {
          await denyWorkflow(workflowId);
          return true;
        } catch (error: any) {
          notification.error({ message: error.message });
          return false;
        }
      }}
    />
  );
};

export {
  ApproveTaskContainer,
};
