import React, { useCallback } from 'react';

import SigninPage from './SigninPage';
import { useAuthDispatch } from '../context/authContext';
import { signIn } from '../context/authThunks';

export const SigninPageContainer = () => {
  const dispatch = useAuthDispatch();
  return (
    <SigninPage
      onSignIn={useCallback(async (email: string, password: string, remember: boolean) => (
        dispatch(signIn(email, password, remember))
      ), [dispatch])}
    />
  );
};

export default SigninPageContainer;
