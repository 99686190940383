import { takeEvery, call } from 'redux-saga/effects';

import {
  FETCH_REGISTRATION_PAYMENTS,
  FETCH_REGISTRATION_TASKS
} from "../actions/registrationActions";

import { fetchRegistrationPayments, fetchRegistrationTasks } from "../api/registration";

// Sagas

export function* fetchRegistrationTasksWorker(action: any): Generator<any, any, any> {
  const response = yield call(fetchRegistrationTasks);
  if (response.status === 'OK') {
    action.meta.resolve(response.payload);
    return response.payload.orders;
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
}

export function* fetchRegistrationPaymentsWorker(action: any): Generator<any, any, any> {
  const response = yield call(fetchRegistrationPayments);
  if (response.status === 'OK') {
    action.meta.resolve(response.payload);
    return response.payload.orders;
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
}

export default function* orders() {
  yield takeEvery(FETCH_REGISTRATION_TASKS, fetchRegistrationTasksWorker);
  yield takeEvery(FETCH_REGISTRATION_PAYMENTS, fetchRegistrationPaymentsWorker);
}
