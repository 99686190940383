import { process } from '../env';
import { commonFetch } from './apiHelper';

export function fetchShops() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/shops`;
  return commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}
