import React from 'react';
import { Table, Tag, Tooltip, Space, Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined, DownloadOutlined,
  PlusCircleOutlined, QuestionCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import type { SorterResult } from 'antd/lib/table/interface';
import type { TablePaginationConfig } from 'antd/lib/table';

import './SummaryTable.scss';

import { OrderStatus, OrderStatusText } from '../../../constants/order';
import { SchemaType } from '../../../constants/schema';
import { ProductItemStatusText } from '../../../constants/productItem';
import { getDateTimeString } from '../../../utils/categoriesHelper';
import { OrderSummaryListContextState, Sorting } from './context/orderSummaryListReducer';
import type { ListItem } from './context/orderSummaryListSelectors';
import { ExtraTabSection } from '../OrdersPage';

enum Status {
  Processing = 'processing',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}

const colorsSettings = {
  [Status.Processing]: [
    OrderStatus.New,
  ],
  [Status.Warning]: [
    OrderStatus.Refunded,
    OrderStatus.PartialRefunded,
  ],
  [Status.Error]: [
    OrderStatus.Canceled,
  ],
  [Status.Success]: [
    OrderStatus.Confirmed,
  ],
};

const statusColors = Object.entries(colorsSettings).reduce((accum, [ key, value ]) => ({
  ...accum,
  ...value.reduce((sum: any, item: any) => {
    // eslint-disable-next-line no-param-reassign
    sum[item] = key;
    return sum;
  }, {}),
}), {} as Record<string, any>);

const iconsSettings = {
  clock: [
    OrderStatus.New,
  ],
  warning: [
    OrderStatus.Refunded,
    OrderStatus.PartialRefunded,
  ],
  error: [
    OrderStatus.Canceled,
  ],
  finish: [
    OrderStatus.Confirmed,
  ],
};

const statusIcons = Object.entries(iconsSettings).reduce((accum, [ key, value ]) => ({
  ...accum,
  ...value.reduce((sum: any, item: any) => {
    // eslint-disable-next-line no-param-reassign
    sum[item] = key;
    return sum;
  }, {}),
}), {} as Record<string, any>);

const renderStatus = (status: Status) => {
  let icon;
  const text = OrderStatusText[status];
  const color = statusColors[status];
  const iconName = statusIcons[status];
  switch (iconName) {
    case 'plus':
      icon = <PlusCircleOutlined />;
      break;
    case 'clock':
      icon = <ClockCircleOutlined />;
      break;
    case 'warning':
      icon = <WarningOutlined />;
      break;
    case 'error':
      icon = <CloseCircleOutlined />;
      break;
    case 'finish':
      icon = <CheckCircleOutlined />;
      break;
    default:
      icon = <QuestionCircleOutlined />;
      break;
  }

  return (
    <Tooltip title={status}>
      <Tag icon={icon} color={color}>{text}</Tag>
    </Tooltip>
  );
};

const formatDate = (date: string) => (dayjs(date).format('DD.MM.YYYY HH:mm').toString());

interface Props {
  onFetch: () => void;
  accountId: string;
  orders: ListItem[];
  sorting: Sorting;
  pagination: OrderSummaryListContextState['pagination'];
  onSetPagination: (pagination: OrderSummaryListContextState['pagination']) => void;
  filter: OrderSummaryListContextState['filter'];
  onSetFilter: (filter: OrderSummaryListContextState['filter']) => void;
  loading: boolean;
  onSetSorting: (sorting: Sorting) => void;
  onExportCsv: () => void;
}

export class SummaryTable extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  handleTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<any> | SorterResult<any>[]) {
    const { onSetPagination, onFetch, onSetSorting } = this.props;
    onSetPagination(pagination as OrderSummaryListContextState['pagination']);
    if (!Array.isArray(sorter)) {
      onSetSorting({
        columnKey: sorter.columnKey,
        order: sorter.order,
      });
    }
    onFetch();
  }

  render() {
    const { accountId, orders, sorting, pagination, loading, onExportCsv, filter, onSetFilter } = this.props;
    const columns = [ {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
      ellipsis: true,
    }, {
      title: 'Дата',
      dataIndex: 'created',
      width: 150,
      render: (created: string) => formatDate(created),
      key: 'created',
      sorter: true,
      sortOrder: sorting.columnKey === 'created' ? sorting.order : undefined,
    }, {
      title: 'Статус (Продажа)',
      dataIndex: 'status',
      width: 170,
      ellipsis: true,
      render: renderStatus,
      key: 'status',
      sorter: true,
      sortOrder: sorting.columnKey === 'status' ? sorting.order : undefined,
    }, {
      title: 'Почта',
      dataIndex: 'customerEmail',
      ellipsis: true,
      width: 200,
      key: 'customerEmail',
      sorter: true,
      sortOrder: sorting.columnKey === 'customerEmail' ? sorting.order : undefined,
    }, {
      title: 'Имя',
      dataIndex: 'customerName',
      ellipsis: true,
      key: 'customerName',
      sorter: true,
      sortOrder: sorting.columnKey === 'customerName' ? sorting.order : undefined,
    }, {
      title: 'Телефон',
      dataIndex: 'customerPhone',
      width: 165,
      key: 'customerPhone',
      sorter: true,
      sortOrder: sorting.columnKey === 'customerPhone' ? sorting.order : undefined,
    }, {
      title: 'Сумма',
      width: 85,
      dataIndex: 'finalPrice',
      key: 'finalPrice',
      sorter: true,
      sortOrder: sorting.columnKey === 'finalPrice' ? sorting.order : undefined,
    }, {
      title: 'Доход',
      dataIndex: 'revenue',
      width: 85
    }, {
      title: 'Смена статуса',
      dataIndex: 'statusChanged',
      width: 165,
      render: (statusChanged: string) => formatDate(statusChanged),
      key: 'statusChanged',
      sorter: true,
      sortOrder: sorting.columnKey === 'statusChanged' ? sorting.order : undefined,
    } ];

    return (
      <>
        <ExtraTabSection>
          <Space className="clickpass-tabs-bar">
            <DatePicker.RangePicker
              // TODO: Check this
              // @ts-ignore
              value={filter.dates}
              onChange={(v) => {
                // TODO: Check this
                // @ts-ignore
                onSetFilter({ dates: v });
              }}
            />
            <Button
              onClick={onExportCsv}
              icon={<DownloadOutlined />}

            >
              Скачать CSV
            </Button>
          </Space>
        </ExtraTabSection>
        <Table
          loading={loading}
          columns={columns}
          dataSource={orders}
          rowKey="id"
          showSorterTooltip={false}
          style={{ width: '100%' }}
          // TODO: Косячит переключение в полноэкранный режим
          scroll={{ x: 1300, y: 'calc(100vh - 206px)' }}
          pagination={pagination}
          onChange={this.handleTableChange}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                Order ID:
                {' '}
                {record.id}
                <hr />
                {record.productItems.map((productItem: any) => (
                  <div key={productItem.id}>
                    Товар&nbsp;
                    {productItem.id}
                    &nbsp;
                    <b>{ProductItemStatusText[productItem.status]}</b>
                    <br />
                    {productItem.specification.title}
                    &nbsp;
                    <br />
                    {
                      productItem.specification.type === SchemaType.BaseCalendar
                      && (
                        <>
                          {getDateTimeString(productItem)}
                          <br />
                        </>
                      )
                    }
                    {
                      productItem.specification.type !== SchemaType.MultiTicket
                      && productItem.item.categoryId
                      && `${productItem.specification.categories
                        .find((c: any) => c.id === productItem.item.categoryId).name} `
                    }
                    {
                      productItem.specification.type === SchemaType.MultiTicket
                      && productItem.item.categoryId
                      && `${productItem.specification.components[0].categories
                        .find((c: any) => c.id === productItem.item.categoryId).name} `
                    }
                    {productItem.item.price}
                    &nbsp;
                    руб.
                    <hr />
                  </div>
                ))}
              </div>
            ),
          }}
        />
      </>
    );
  }
}
