import { asValue, asClass, createContainer } from 'awilix';
import { RbacManager } from '@brainstaff/rbac';

import { process } from '../env';

import ConsoleLoggerService from '../services/ConsoleLoggerService';

import RbacRuleFactory from '../rbac/RbacRuleFactory';
import Providers from './providers';
import ComponentNotificationService from '../services/ComponentNotificationService';

const { RbacInMemoryAdapter } = require('@brainstaff/rbac-in-memory');
const { RbacHttpAdapter } = require('@brainstaff/rbac-http');

// Creating IoC container

export const container = createContainer();

container.register({
  logger: asClass(ConsoleLoggerService).singleton(),
});

container.register(Providers.componentNotificationService, asClass(ComponentNotificationService).singleton());
container.register({
  rbacHttpConfiguration: asValue({
    baseUrl: process.env.BACKEND_URL + '/api/v1',
    get headers() {
      return {
        'Content-Type': 'application/json',
      };
    },
    withCredentials: true,
  }),
  rbacCacheAdapter: asClass(RbacInMemoryAdapter).singleton(),
  rbacPersistentAdapter: asClass(RbacHttpAdapter).singleton(),
  rbacRuleFactory: asClass(RbacRuleFactory).singleton(),
  rbacManager: asClass(RbacManager).singleton(),
});

export default container;
