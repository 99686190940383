import React, { useCallback } from 'react';

import RestorePage from './RestorePage';
import { useAuthDispatch } from '../context/authContext';
import { changePassword, restorePassword } from '../context/authThunks';

export const RestorePageContainer = () => {
  const dispatch = useAuthDispatch();
  return (
    <RestorePage
      onRestore={useCallback(async (email: string) => (
        dispatch(restorePassword(email))
      ), [dispatch])}
      onChange={useCallback(async (email: string, password: string, passwordRepeat: string, code: string) => (
        dispatch(changePassword({ email, password, passwordRepeat, code }))
      ), [dispatch])}
    />
  );
};

export default RestorePageContainer;
