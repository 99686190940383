import { takeEvery, call, put } from 'redux-saga/effects';

import {
  FETCH_INSPECTORS,
} from '../actions/inspectorsActions';

import { fetchInspectors, createInspector, deleteInspector, updateInspector } from '../api/inspectors';
import { CREATE_INSPECTOR, DELETE_INSPECTOR, setInspectors, UPDATE_INSPECTOR } from '../actions/inspectorsActions';

export function* fetchInspectorsWorker() {
  const response = yield call(fetchInspectors);
  if (response.status === 'OK') {
    yield put(setInspectors(response.payload.inspectors));
    return response.payload.inspectors;
  } else if (response.status === 'ERROR') {
    return null;
  }
}

export function* createInspectorWorker(action) {
  const response = yield call(createInspector, action.payload.inspector);
  if (response.status === 'OK') {
    yield put(setInspectors(response.payload.inspectors));
    action.meta.resolve();
    return response.payload.inspectors;
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
}

export function* updateInspectorWorker(action) {
  const response = yield call(updateInspector, action.payload.id, action.payload.inspector);
  if (response.status === 'OK') {
    yield put(setInspectors(response.payload.inspectors));
    action.meta.resolve();
    return response.payload.inspectors;
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
}

export function* deleteInspectorWorker(action) {
  const response = yield call(deleteInspector, action.payload.id);
  if (response.status === 'OK') {
    yield put(setInspectors(response.payload.inspectors));
    action.meta.resolve();
    return response.payload.inspectors;
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
}

export default function* inspectors() {
  yield takeEvery(FETCH_INSPECTORS, fetchInspectorsWorker);
  yield takeEvery(CREATE_INSPECTOR, createInspectorWorker);
  yield takeEvery(UPDATE_INSPECTOR, updateInspectorWorker);
  yield takeEvery(DELETE_INSPECTOR, deleteInspectorWorker);
}
