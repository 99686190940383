import React, { useEffect, useState } from 'react';
import uniq from 'lodash.uniq';

import './CategoriesList.scss';

import { MultiTicketCalendarV1CategoryType, MultiTicketCalendarV1ComponentType } from '../multiTicketCalendarV1';

import { CategoriesList, CategoriesListProps } from './CategoriesList';
import { fetchProductSpecificationById } from '../../../../../api';

export interface CategoriesListContainerProps extends Omit<CategoriesListProps, 'componentSpecifications'> {
}

export const CategoriesListContainer: React.FC<CategoriesListContainerProps> = (props) => {
  const { categories } = props;
  const [ componentSpecifications, setComponentSpecification ] = useState<any[]>([]);

  let componentSpecificationIds = categories
    .reduce<string[]>((allIds, category: MultiTicketCalendarV1CategoryType) => {
      const currentIds = category.components.map(
        (component: MultiTicketCalendarV1ComponentType) => component.productSpecificationId
      );
      return [ ...allIds, ...currentIds ];
    }, []);
  componentSpecificationIds = uniq(componentSpecificationIds).sort();

  useEffect(() => {
    componentSpecificationIds
      .filter((specificationId) => {
        return !componentSpecifications.find((specification) => specification.id === specificationId);
      })
      .forEach(async (specificationId) => {
        const response = await fetchProductSpecificationById(specificationId);
        setComponentSpecification((currentComponentSpecifications) => [
          ...currentComponentSpecifications,
          response.payload.product,
        ]);
      });
  }, [ componentSpecificationIds.join() ]);

  return (
    <CategoriesList
      {...props}
      componentSpecifications={componentSpecifications}
    />
  );
};
