import { connect } from 'react-redux';

import { downloadReport, fetchReports } from '../../actions/reportsActions';

import ReportsPage from './ReportsPage';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  onLoadReports(): Promise<any> {
    return new Promise((resolve, reject) => {
      dispatch(fetchReports(resolve, reject));
    });
  },
  onDownloadReport(fileId: string, name: string): Promise<void> {
    return new Promise((resolve, reject) => {
      dispatch(downloadReport(fileId, name, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
