import React, { ElementType } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PrivateRoutes } from '../../../constants/routes';

import { ApplicationLayout } from '../layouts/application-layout/ApplicationLayout';

import ProductsPageContainer from '../../products-page/ProductsPageContainer';
import OrderPageWrapper from '../../orders-page/OrdersPageContainer';
import ScannerPageContainer from '../../scanner-page/ScannerPageContainer';
import { PartnersPageContainer } from '../../partners-page/PartnersPageContainer';
import PurchasesPageWrapper from '../../purchases-page/PurchasesPageContainer';

import { InspectorsPageContainer } from '../../inspectors-page';
import ReportsPageContainer from '../../reports-page/ReportsPageContainer';
import { AccountPageContainer } from '../../account-page/AccountPageContainer';
import { BalancePage } from '../../balance-page/BalancePage';
import { ChildPartnersWrapper } from '../../child-partners/ChildPartnersContainer';
import { LoginAsPageContainer } from '../../authentication/login-as-page/LoginAsPageContainer';

interface AuthenticatedRoutesDependencies {
  isAdministrator: boolean;
  isOrganizer: boolean;
  isDistributor: boolean;
  isInspector: boolean;
}

const AuthenticatedRoutes = ({
  isAdministrator,
  isOrganizer,
  isDistributor,
  isInspector,
}: AuthenticatedRoutesDependencies) => {
  let DefaultRouteComponent: ElementType = ProductsPageContainer;
  if (isInspector) {
    DefaultRouteComponent = ApplicationLayout;
  }
  if (isDistributor) {
    DefaultRouteComponent = OrderPageWrapper;
  }
  if (isOrganizer) {
    DefaultRouteComponent = ProductsPageContainer;
  }

  return (
    <Routes>
      {isOrganizer && <Route path={PrivateRoutes.Products} element={<ProductsPageContainer />} />}
      {isOrganizer && <Route path={PrivateRoutes.Orders} element={<OrderPageWrapper />} />}
      {isOrganizer && <Route path={PrivateRoutes.Purchases} element={<PurchasesPageWrapper />} />}
      {isOrganizer && (
        <Route
          path={PrivateRoutes.Balance}
        >
          <Route
            path="*"
            element={<BalancePage />}
          />
        </Route>
      )}
      {
        (isOrganizer || isInspector) && (
          <Route path={PrivateRoutes.Scanner} element={<ScannerPageContainer />} />
        )
      }
      {isOrganizer && <Route path={PrivateRoutes.Inspectors} element={<InspectorsPageContainer />} />}
      {isAdministrator && <Route path={PrivateRoutes.Partners} element={<PartnersPageContainer />} />}
      {isAdministrator && <Route path={PrivateRoutes.LoginAs} element={<LoginAsPageContainer />} />}
      {
        (isOrganizer || isDistributor) && (
          <>
            <Route path={PrivateRoutes.Reports} element={<ReportsPageContainer />} />
            <Route path={PrivateRoutes.ChildPartners} element={<ChildPartnersWrapper />} />
            <Route path={PrivateRoutes.Account} element={<AccountPageContainer />} />
          </>
        )
      }
      <Route path="*" element={<DefaultRouteComponent />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
