import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';
import store from './store';

import './index.scss';

import ApplicationContainer from './components/application/ApplicationContainer';

import './components/ant/Button.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.locale('ru');

ReactDOM.render((
  <Provider store={store}>
    <ApplicationContainer />
  </Provider>
), document.getElementById('root'));
