import { notification } from 'antd';
import { produce } from 'immer';
import { container } from 'ioc-container/container';
import { Providers } from 'ioc-container/providers';
import { AuthEventType } from '../services/ComponentNotificationService';

// eslint-disable-next-line no-undef
export const commonNonAuthFetch = async (input: RequestInfo, init: RequestInit = {}, showErrors = true) => {
  try {
    const fetchInit = produce(init, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.credentials = 'include';
      return draft;
    });
    const result = await fetch(input, fetchInit);
    if (result.status === 500) {
      try {
        const response = await result.json();
        if (response.message) {
          notification.error({ message: 'Ошибка', description: response.message });
        } else {
          notification.error({
            message: 'Внутренняя ошибка сервера', description: 'Пожалуйста, обратитесь в слуюбу технической поддержки',
          });
        }
      } catch (error) {
        notification.error({
          message: 'Внутренняя ошибка сервера', description: 'Пожалуйста, обратитесь в слуюбу технической поддержки',
        });
      }
    }
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    if (showErrors) {
      notification.error({
        message: 'Произошла сетевая ошибка', description: 'Пожалуйста, проверьте свое соединение с интернетом',
      });
    }
    throw error;
  }
};

// eslint-disable-next-line no-undef
export const commonFetch = async (input: RequestInfo, init: RequestInit, showErrors = true) => {
  const result = await commonNonAuthFetch(input, init, showErrors);
  if (result.status === 401) {
    container.resolve(Providers.componentNotificationService).emit(AuthEventType.UnauthorizedRequest);
  }
  return result;
};

const encodeParam = (key: string, value: any) => {
  if (Array.isArray(value)) {
    return value.map((valueItem) => `${encodeURIComponent(`${key}[]`)}=${encodeURIComponent(valueItem)}`).join('&');
  }
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};

export const encodeQueryFilter = (filter: { [key: string]: any }) => {
  const filterKeys = Object.keys(filter);
  if (!filterKeys.length) {
    return '';
  }

  return `?${filterKeys.map((key) => encodeParam(key, filter[key])).join('&')
  }`;
};

export enum ResponseStatus {
  OK = 'OK',
  ERROR = 'ERROR',
}

interface SuccessResponse<T> {
  status: ResponseStatus.OK;
  payload: T;
}

interface ErrorResponse {
  status: ResponseStatus.ERROR;
  error: {
    message: string
  };
}

export type Response<T> = SuccessResponse<T> | ErrorResponse;

export const getResponse = <T>(response: Response<T>) => {
  if (response.status === ResponseStatus.OK) {
    return response?.payload;
  }
  return undefined;
};

type FetchResponse = Awaited<ReturnType<typeof fetch>>;

export class ApiError extends Error {
  private _response: FetchResponse;

  constructor(response: FetchResponse) {
    super('Api error');
    this.name = this.constructor.name;
    Error.captureStackTrace(this, this.constructor);
    this._response = response;
  }

  public get response() {
    return this._response;
  }
}
