import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { productGroupsSelector, productsSelector } from '../../selectors/productsSelectors';
import { fetchProduct, fetchProducts, updateProduct } from '../../actions/productsActions';
import { deleteProductGroup } from '../../actions/productGroupsActions';

import { ProductsPage, ProductSpecificationInterface } from './ProductsPage';
import { useAuthState } from '../authentication/context/authContext';
import { createIsUserRoleSelector, currentUserSelector } from '../authentication/context/authSelectors';
import { Role } from '../../constants/rbac';


const ProductsPageContainer = () => {
  const state = useAuthState();
  const dispatch = useDispatch();
  const { accountId } = currentUserSelector(state)!;
  return (
    <ProductsPage
      products={useSelector(productsSelector)}
      productGroups={useSelector(productGroupsSelector)}
      accountId={accountId}
      onLoadProducts={useCallback(() => (
        new Promise((resolve, reject) => {
          dispatch(fetchProducts(resolve, reject));
        })
      ), [])}
      onLoadProduct={useCallback((productSpecificationId: string): Promise<{
        product: ProductSpecificationInterface
      }> => (
        new Promise((resolve, reject) => {
          dispatch(fetchProduct(productSpecificationId, resolve, reject));
        })
      ), [])}
      onUpdateProduct={useCallback((productSpecificationId: string, productSpecificationDto: any) => (
        new Promise((resolve, reject) => {
          dispatch(updateProduct(productSpecificationId, productSpecificationDto, resolve, reject));
        })
      ), [])}
      onDeleteProductGroup={useCallback((productGroupId: string): Promise<void> => (
        new Promise((resolve, reject) => {
          dispatch(deleteProductGroup(productGroupId, resolve, reject));
          resolve();
        })
      ), [])}
      isMutiTicketManager={createIsUserRoleSelector(Role.MultiticketManager)(state)}
    />
  );
};

export default ProductsPageContainer;
