import React from 'react';
import * as Yup from 'yup';

import { Modal, InputNumber, Select, Divider } from 'antd';
import { Formik, Form, ErrorMessage } from 'formik';

import './PlainProductFormModal.scss';
import { SchemaType } from '../../../../constants/schema';
import { InputField } from '../../../../utils/form-helpers/antd-formik';

export class PlainProductFormModal extends React.Component {
  constructor() {
    super();
    this.onFinish = this.onFinish.bind(this);
  }

  async onFinish(values, { setSubmitting, setErrors }) {
    const { product, onCreateProduct, onUpdateProduct, onComplete } = this.props;
    try {
      if (product) {
        await onUpdateProduct(product.id, values);
      } else {
        await onCreateProduct(values);
      }
      onComplete();
    } catch (error) {
      setErrors(error);
      setSubmitting(false);
    }
  }

  validationSchema = Yup.object().shape({
    productGroupId: Yup.string().nullable().default(null),
    specification: Yup.object({
      type: Yup.string().default(SchemaType.Plain),
      version: Yup.number().default(1),
      title: Yup.string().required('Введите название продукта.'),
      description: Yup.string().required('Введите описание продукта.'),
      price: Yup.number().required('Введите цену продукта.'),
    }).required(),
  });

  render() {
    const { open, onCancel, productGroups } = this.props;
    const { product } = this.props;
    const title = product ? 'Обновить товар' : 'Добавить товар';
    const okText = product ? 'Обновить' : 'Добавить';
    const defaultProduct = {
      productGroupId: null,
      specification: {
        type: SchemaType.Plain,
        version: 1,
        title: 'Новый товар 1',
        description: 'Описание нового товара',
        price: 100.0,
      },
    }
    return (
      <Formik
        enableReinitialize={true}
        initialValues={product || defaultProduct}
        validationSchema={this.validationSchema}
        onSubmit={this.onFinish}
      >
        {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
          <Modal
            open={open}
            title={title}
            onCancel={onCancel}
            okText={okText}
            onOk={handleSubmit}
            destroyOnClose={true}
          >
            <div className="PlainProductFormModal">
              <Form>
                <table width="100%">
                  <tbody>
                  <tr>
                    <td>
                      Название:<br />
                      <InputField name="specification.title" />
                      <ErrorMessage name={`specification.title`}>
                        {(msg) => <div className="PlainProductFormModal-error">{msg}</div>}
                      </ErrorMessage>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Описание:<br />
                      <InputField.TextArea name="specification.description" />
                      <ErrorMessage name={`specification.description`}>
                        {(msg) => <div className="PlainProductFormModal-error">{msg}</div>}
                      </ErrorMessage>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Цена:<br />
                      <InputNumber
                        value={values.specification.price}
                        onChange={(value) => setFieldValue('specification.price', value)}
                        onBlur={() => setFieldTouched('specification.price', true)}
                      />
                      <ErrorMessage name={`specification.price`}>
                        {(msg) => <div className="PlainProductFormModal-error">{msg}</div>}
                      </ErrorMessage>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Divider />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Группа:<br />
                      <Select
                        style={{ width: '200px' }}
                        value={values.productGroupId}
                        onChange={(value) => setFieldValue('productGroupId', value)}
                        onBlur={() => setFieldTouched('productGroupId', true)}
                      >
                        <Select.Option value={null}>Не выбрана</Select.Option>
                        {productGroups.map(productGroup => (
                          <Select.Option
                            key={productGroup.id}
                            value={productGroup.id}
                          >
                            {productGroup.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                  </tr>
                  </tbody>
                </table>
                {errors?.summary && <div className="PlainProductFormModal-error">{errors.summary}</div>}
              </Form>
            </div>
          </Modal>
        )}
      </Formik>
    );
  }
}

export default PlainProductFormModal;
