import React, { useCallback } from 'react';
import { ProductsSummary } from './ProductsSummary';
import { useProductSummaryListDispatch, useProductSummaryListState } from './context/productSummaryListContext';
import {
  accountIdSelector,
  listItemSelector,
  listLoadingSelector,
  sortingSelector,
  paginationSelector,
  filterSelector,
} from './context/productSummaryListSelectors';
import {
  ProductSummaryListContextState,
  storePagination,
  storeSorting,
  storeFilter,
  initPagination,
} from './context/productSummaryListReducer';
import { getList, getListCsv } from './context/productsSumaryListThunks';
import isEqual from 'lodash.isequal';

export const ProductsSummaryContainer = () => {
  const state = useProductSummaryListState();
  const dispatch = useProductSummaryListDispatch();
  const accountId = accountIdSelector(state);
  const listItems = listItemSelector(state);
  const filter = filterSelector(state);
  return (
    <ProductsSummary
      accountId={accountId}
      productItems={listItems}
      sorting={sortingSelector(state)}
      loading={listLoadingSelector(state)}
      pagination={paginationSelector(state)}
      filter={filter}
      onSetPagination={useCallback((pagination: ProductSummaryListContextState['pagination']) => {
        dispatch(storePagination(pagination));
      }, [dispatch])}
      onSetSorting={useCallback((sorting) => {
        dispatch(storeSorting(sorting));
      }, [dispatch])}
      onSetFilter={useCallback((newFilter: ProductSummaryListContextState['filter']) => {
        if (!isEqual(newFilter, filter)) {
          dispatch(storeFilter(newFilter));
          dispatch(storePagination(initPagination));
          dispatch(getList);
        }
      }, [dispatch, filter])}
      onFetch={useCallback(() => dispatch(getList), [dispatch])}
      onExportCsv={useCallback(() => {
        dispatch(getListCsv);
      }, [accountId, listItems])}
    />
  );
};
