export enum ReportTypeLabel {
  DISTRIBUTOR = 'Для Распространителя',
  ORGANIZER = 'Для Организатора',
  'nominal-organizer' = 'Для Органиазтора Н',
  'nominal-distributor' = 'Для Распространителя Н',
}

export type FileMetadata = {
  id: string
  name: string
  mimeType: string
}
