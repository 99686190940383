import React, { useCallback } from 'react';

import OrdersPage from './OrdersPage';
import {
  OrderSummaryListProvider,
  useOrderSummaryListDispatch,
} from './summary-table/context/orderSummaryListContext';
import { getList } from './summary-table/context/orderSumaryListThunks';
import { useAuthState } from '../authentication/context/authContext';
import { currentUserSelector } from '../authentication/context/authSelectors';

export const OrdersPageContainer = () => {
  const dispatch = useOrderSummaryListDispatch();
  return (
    <OrdersPage
      loadOrders={useCallback(async () => (
        dispatch(getList)
      ), [dispatch])}
    />
  );
};

export const OrderPageWrapper = () => {
  const state = useAuthState();
  const { accountId } = currentUserSelector(state)!;
  return (
    <OrderSummaryListProvider initState={{ accountId }}>
      <OrdersPageContainer />
    </OrderSummaryListProvider>
  );
};

export default OrderPageWrapper;
