import { takeEvery, call } from 'redux-saga/effects';

import { DOWNLOAD_REPORT, FETCH_REPORTS } from '../actions/reportsActions';
import { downloadReport, fetchReports } from '../api';

// Saga workers

export function* fetchReportsWorker(action: any) {
  // @ts-ignore
  const response = yield call(fetchReports);
  if (response.status === 'OK') {
    action?.meta?.resolve(response.payload.reports);
    return response.payload.reports;
  }
  if (response.status === 'ERROR') {
    action?.meta?.reject();
    return null;
  }
  return null;
}

export function* downloadReportWorker(action: any) {
  const { content } = yield call(downloadReport, action.payload.fileId);

  // @ts-ignore
  if (navigator.msSaveBlob) {
    // @ts-ignore
    navigator.msSaveBlob(content, action.payload.name);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(content);
    link.setAttribute('download', action.payload.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

// Saga watchers

export default function* users() {
  yield takeEvery(FETCH_REPORTS, fetchReportsWorker);
  yield takeEvery(DOWNLOAD_REPORT, downloadReportWorker);
}
