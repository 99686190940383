import React from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { Formik } from 'formik';
import { NavLink } from 'react-router-dom';

import './RestorePage.scss';

import NonAuthLayout from '../../application/layouts/non-auth-layout/NonAuthLayout';

const { Title } = Typography;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 19,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 19,
  },
};

interface State {
  step: string;
  email: string;
}

interface Props {
  onRestore: (email: string) => Promise<void>;
  onChange: (email: string, password: string, passwordRepeat: string, code: string) => Promise<void>;
}

class RestorePage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      step: 'restore',
      email: '',
    };
    this.onValidateRestore = this.onValidateRestore.bind(this);
    this.onFinishRestore = this.onFinishRestore.bind(this);
    this.onValidateChange = this.onValidateChange.bind(this);
    this.onFinishChange = this.onFinishChange.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  onValidateRestore(values: any) {
    const errors: Record<string, string> = {};
    if (!values.email) {
      errors.email = 'Требуется адрес почты.';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Некорректный адрес почты.';
    }
    return errors;
  }

  async onFinishRestore(values: any, { setSubmitting, setErrors }: any) {
    const { onRestore } = this.props;
    const { email } = values;
    try {
      await onRestore(email);
      setTimeout(() => {
        this.setState(() => ({ step: 'change', email }));
      }, 500);
    } catch (error) {
      console.log(error);
      setErrors(error);
    }
    setSubmitting(false);
  }

  // eslint-disable-next-line react/sort-comp
  renderRestoreStep() {
    return (
      <Formik
        key="restore-form"
        initialValues={{
          email: '',
        }}
        validate={this.onValidateRestore}
        onSubmit={this.onFinishRestore}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form {...layout} onFinish={handleSubmit}>
            <Form.Item {...tailLayout}>
              <Title level={1}>Восстановить доступ</Title>
            </Form.Item>
            <Form.Item {...tailLayout}>
              Если вы забыли свой пароль, то введите свой
              почтовый адрес ниже и мы вышлем код для
              восстановления.
            </Form.Item>
            <Form.Item
              label="Почта"
              name="email"
              validateStatus={errors && errors.email ? 'error' : undefined}
              help={errors && errors.email && touched.email && errors.email}
            >
              <Input onChange={handleChange} onBlur={handleBlur} value={values.email} />
            </Form.Item>
            {
              errors && (errors as any).summary
              && (
                <Form.Item {...tailLayout} style={{ color: 'red' }}>
                  {(errors as any).summary}
                </Form.Item>
              )
            }
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" disabled={isSubmitting}>Отправить</Button>
              &nbsp;&nbsp;&nbsp;
              - или -
              &nbsp;&nbsp;
              <NavLink to="/">Войти</NavLink>
            </Form.Item>
          </Form>
        )}
      </Formik>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  onValidateChange(values: any) {
    const errors: Record<string, string> = {};
    if (!values.email) {
      errors.email = 'Необходим почтовый адрес.';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Некоректный почтовый адрес.';
    }
    if (!values.password) {
      errors.password = 'Неоходим пароль.';
    }
    if (!values.passwordRepeat) {
      errors.passwordRepeat = 'Необходим повтор пароля.';
    }
    if (!values.code) {
      errors.code = 'Необходим код восстановления.';
    }
    if (values.password !== values.passwordRepeat) {
      errors.passwordRepeat = 'Пароль и повтор должны совпадать.';
    }
    return errors;
  }

  async onFinishChange(values: any, { setSubmitting, setErrors }: any) {
    const { onChange } = this.props;
    const { email, password, passwordRepeat, code } = values;
    try {
      await onChange(email, password, passwordRepeat, code);
      setTimeout(() => {
        this.setState(() => ({ step: 'finish' }));
      }, 500);
    } catch (error) {
      setErrors(error);
    }
    setSubmitting(false);
  }

  renderChangeStep() {
    const { email } = this.state;
    return (
      <Formik
        key="change-form"
        initialValues={{
          email,
          password: '',
          passwordRepeat: '',
          code: '',
        }}
        validate={this.onValidateChange}
        onSubmit={this.onFinishChange}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form {...layout} onFinish={handleSubmit}>
            <Form.Item {...tailLayout}>
              <Title level={1}>Сменить</Title>
            </Form.Item>
            <Form.Item {...tailLayout}>
              Установите новый пароль, указав код из почты:
            </Form.Item>
            <Form.Item
              label="Почта"
              validateStatus={errors && errors.email ? 'error' : undefined}
              help={errors && errors.email && touched.email && errors.email}
            >
              {values.email}
            </Form.Item>
            <Form.Item
              label="Пароль"
              name="password"
              validateStatus={errors && errors.password ? 'error' : undefined}
              help={errors && errors.password && touched.password && errors.password}
            >
              <Input.Password onChange={handleChange} onBlur={handleBlur} value={values.password} />
            </Form.Item>
            <Form.Item
              label="Повтор"
              name="passwordRepeat"
              validateStatus={errors && errors.passwordRepeat ? 'error' : undefined}
              help={errors && errors.passwordRepeat && touched.passwordRepeat && errors.passwordRepeat}
            >
              <Input.Password onChange={handleChange} onBlur={handleBlur} value={values.password} />
            </Form.Item>
            <Form.Item
              label="Код"
              name="code"
              validateStatus={errors && errors.code ? 'error' : undefined}
              help={errors && errors.code && touched.code && errors.code}
            >
              <Input onChange={handleChange} onBlur={handleBlur} value={values.code} />
            </Form.Item>
            {
              errors && (errors as any).summary
              && (
                <Form.Item {...tailLayout} style={{ color: 'red' }}>
                  {(errors as any).summary}
                </Form.Item>
              )
            }
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" disabled={isSubmitting}>Сменить</Button>
              &nbsp;&nbsp;&nbsp;
              - или -
              &nbsp;&nbsp;
              <NavLink to="/">Войти</NavLink>
            </Form.Item>
          </Form>
        )}
      </Formik>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderFinishStep() {
    return (
      <div>
        Теперь вы можете&nbsp;
        <NavLink to="/">Войти</NavLink>
        &nbsp;с новым паролем!
      </div>
    );
  }

  render() {
    const { step } = this.state;
    return (
      <NonAuthLayout>
        <div className="RestorePage">
          <div className="RestorePage-form">
            {step === 'restore' && this.renderRestoreStep()}
            {step === 'change' && this.renderChangeStep()}
            {step === 'finish' && this.renderFinishStep()}
          </div>
        </div>
      </NonAuthLayout>
    );
  }
}

export default RestorePage;
