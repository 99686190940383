import React from 'react';
import { Table, Space, Button, DatePicker } from 'antd';
import type { TablePaginationConfig } from 'antd/lib/table';
import type { SorterResult } from 'antd/lib/table/interface';

import { SchemaType } from '../../../constants/schema';

import { formatDate } from '../utils/formatDate';
import { ProductItemStatusText } from '../../../constants/productItem';
import { getDateTimeString } from '../../../utils/categoriesHelper';
import { Sorting } from './context/productSummaryListReducer';
import { OrderSummaryListContextState } from '../../orders-page/summary-table/context/orderSummaryListReducer';
import { ExtraTabSection } from '../PurchasesPage';
import { DownloadOutlined } from '@ant-design/icons';

interface Props {
  accountId: string;
  productItems: any[];
  sorting: Sorting;
  onFetch: () => void,
  pagination: OrderSummaryListContextState['pagination'],
  onSetPagination: (pagination: OrderSummaryListContextState['pagination']) => void,
  filter: OrderSummaryListContextState['filter'],
  onSetFilter: (filter: OrderSummaryListContextState['filter']) => void,
  loading: boolean,
  onSetSorting: (sorting: Sorting) => void,
  onExportCsv: () => void;
}

export class ProductsSummary extends React.PureComponent<Props> {

  constructor(props: Props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  handleTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<any> | SorterResult<any>[]) {
    const { onSetPagination, onFetch, onSetSorting } = this.props;
    onSetPagination(pagination as OrderSummaryListContextState['pagination']);
    if (!Array.isArray(sorter)) {
      onSetSorting({
        columnKey: sorter.columnKey,
        order: sorter.order,
      });
    }
    onFetch();
  }

  render() {
    const { productItems, accountId, sorting, pagination, loading, onExportCsv, filter, onSetFilter } = this.props;
    const columns = [{
      title: 'Дата',
      dataIndex: 'created',
      width: 170,
      render: (created: string) => (formatDate(created)),
      key: 'created',
      sorter: true,
      sortOrder: sorting.columnKey === 'created' ? sorting.order : undefined,
    }, {
      title: 'Статус (Билет)',
      dataIndex: 'status',
      width: 150,
      render: (status: string) => ProductItemStatusText[status],
      key: 'status',
      sorter: true,
      sortOrder: sorting.columnKey === 'status' ? sorting.order : undefined,
    }, {
      title: 'Погашен (Билет)',
      dataIndex: 'specification',
      render: (spec: any, record: any) => {
        const hasConsumed = record.consumption.productSpecifications
          ?.filter((productSpecification: any) => productSpecification.consumed === true).length > 0;
        const hasNotConsumed = record.consumption.productSpecifications
          ?.filter((productSpecification: any) => productSpecification.consumed === false).length > 0;
        if (hasConsumed && !hasNotConsumed) {
          return 'Да';
        }
        return (hasConsumed && hasNotConsumed) ? 'Част.' : 'Нет';
      },
      width: 200,
    }, {
      title: 'Почта',
      dataIndex: 'customerEmail',
      width: 200,
      key: 'customerEmail',
      sorter: true,
      sortOrder: sorting.columnKey === 'customerEmail' ? sorting.order : undefined,
    }, {
      title: 'Имя',
      dataIndex: 'customerName',
      width: 200,
      key: 'customerName',
      sorter: true,
      sortOrder: sorting.columnKey === 'customerName' ? sorting.order : undefined,
    }, {
      title: 'Телефон',
      dataIndex: 'customerPhone',
      width: 165,
      key: 'customerPhone',
      sorter: true,
      sortOrder: sorting.columnKey === 'customerPhone' ? sorting.order : undefined,
    }, {
      title: 'Название',
      dataIndex: ['specification', 'title'],
      key: 'specification.title',
      sorter: true,
      sortOrder: sorting.columnKey === 'specification.title' ? sorting.order : undefined,
      render: (_: any, record: any) => {
        let { title } = record.specification;
        const dateString = getDateTimeString(record);
        if (dateString) {
          title += ` ${dateString}`;
        }
        return title;
      },
    }, {
      title: 'Категория',
      dataIndex: 'specification',
      width: 200,
      render: (spec: any, record: any) => {
        let categoryName;
        if (spec.type === SchemaType.Base || spec.type === SchemaType.BaseCalendar) {
          categoryName = spec.categories
            .find((category: any) => category.id === record.item.categoryId)
            .name;
        }
        if (spec.type === SchemaType.MultiTicket) {
          categoryName = spec.components[0]
            .categories
            .find((category: any) => category.id === record.item.categoryId)
            .name;
        }
        return <span>{categoryName}</span>;
      },
    }, {
      title: 'Сумма',
      dataIndex: ['item', 'price'],
      width: 85,
      key: 'item.price',
      sorter: true,
      sortOrder: sorting.columnKey === 'item.price' ? sorting.order : undefined,
    }, {
      title: 'Доход',
      dataIndex: ['item', 'splitPayment'],
      width: 85,
      render: (splitPayment: any, record: any) => {
        if (record.specification.type === 'multi-ticket') {
          return <span>-</span>;
        }
        let data = 0;
        splitPayment.forEach((itSplitPayment: any) => {
          if (itSplitPayment.accountId === accountId && itSplitPayment.productSpecificationId !== null) {
            data += itSplitPayment.amount - itSplitPayment.fee;
          }
        });
        return <span>{data / 100}</span>;
      },
    }, {
      title: <span>Комиссия&nbsp;CP</span>,
      dataIndex: ['item', 'splitPayment'],
      width: 140,
      render: (splitPayment: any, record: any) => {
        if (record.specification.type === 'multi-ticket') {
          return <span>-</span>;
        }
        let data = 0;
        splitPayment.forEach((itSplitPayment: any) => {
          if (itSplitPayment.accountId === accountId && itSplitPayment.productSpecificationId !== null) {
            data += itSplitPayment.fee;
          }
        });
        return <span>{data / 100}</span>;
      },
    }];

    return (
      <>
        <ExtraTabSection>
          <Space className="clickpass-tabs-bar">
            <DatePicker.RangePicker
              value={filter.dates}
              onChange={(v) => {
                onSetFilter({ dates: v });
              }}
            />
            <Button
              onClick={onExportCsv}
              icon={<DownloadOutlined />}
            >
              Скачать CSV
            </Button>
          </Space>
        </ExtraTabSection>
        <Table
          loading={loading}
          columns={columns}
          dataSource={productItems}
          rowKey="id"
          showSorterTooltip={false}
          // TODO: Косячит переключение в полноэкранный режим
          scroll={{ x: 2000, y: 'calc(100vh - 206px)' }}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}
