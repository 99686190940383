import { produce } from 'immer';
import { ChildAccount } from '../../../api';

export const initPagination = {
  pageSize: 10,
  current: 1,
  total: 0,
};

export interface ChildPartnersContextState {
  listItems: {
    accounts: ChildAccount[];
  };
  loading: boolean;
  pagination: {
    pageSize: number,
    current: number,
    total: number,
  };
}

export const initialState = (): ChildPartnersContextState => ({
  listItems: {
    accounts: [],
  },
  loading: false,
  pagination: initPagination,
});

export const STORE_LIST_ITEMS = 'STORE_LIST_ITEMS';
export const storeListItems = (accounts: ChildAccount[]) => ({
  type: STORE_LIST_ITEMS, payload: { accounts },
});

export const STORE_PAGINATION = 'STORE_PAGINATION';
export const storePagination = (pagination: ChildPartnersContextState['pagination']) => ({
  type: STORE_PAGINATION, payload: { pagination },
});

export const SET_ORDER_SUMMARY_LIST_LOADING = 'SET_ORDER_SUMMARY_LIST_LOADING';
export const setChildPartnersLoading = (loading: boolean) => ({
  type: SET_ORDER_SUMMARY_LIST_LOADING, payload: { loading },
});

const childPartnersReducer = (state: ChildPartnersContextState, action: any) => produce(state, (draftState) => {
  switch (action.type) {
    case STORE_LIST_ITEMS: {
      const { accounts } = (action as ReturnType<typeof storeListItems>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.listItems = { accounts };
      break;
    }
    case SET_ORDER_SUMMARY_LIST_LOADING: {
      const { loading } = (action as ReturnType<typeof setChildPartnersLoading>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.loading = loading;
      break;
    }
    case STORE_PAGINATION: {
      const { pagination } = (action as ReturnType<typeof storePagination>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.pagination = pagination;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default childPartnersReducer;
