import { produce } from 'immer';
import { createSelector } from 'reselect';
import { OrderSummaryListContextState } from './orderSummaryListReducer';
import { Order } from '../../../../api/orders';


export type ListItem = Order & {
  productItems: any[];
};

export const listItemSelector = createSelector(
  (state: OrderSummaryListContextState) => state.listItems.orders,
  (state: OrderSummaryListContextState) => state.listItems.productItems,
  (orders, productItems): ListItem[] => (
    produce(orders as ListItem[], (draft) => {
      draft.forEach((order) => {
        // eslint-disable-next-line no-param-reassign
        (order as any).productItems = productItems
          .filter((productItem) => productItem.orderId === order.id);
      });
    })
  ),
);

export const filterSelector = (state: OrderSummaryListContextState) => state.filter;
export const accountIdSelector = (state: OrderSummaryListContextState) => state.accountId;
export const sortingSelector = (state: OrderSummaryListContextState) => state.sorting;
export const paginationSelector = (state: OrderSummaryListContextState) => state.pagination;
export const listLoadingSelector = (state: OrderSummaryListContextState) => state.loading;
