import React, { useCallback } from 'react';
import { FieldProps } from 'formik';
import {
  Input as _Input,
  InputRef,
  InputProps as _InputProps,
} from 'antd';
import { TextAreaProps as _TextAreaProps, TextAreaRef } from 'antd/lib/input/TextArea';
import { PasswordProps as _PasswordProps } from 'antd/lib/input/Password';

import Field from './Field';
import { FormikFieldProps } from './FieldProps';
import { makeField, memoField } from './makeField';

type InputProps = FieldProps & _InputProps;
type PasswordProps = FieldProps & _PasswordProps;
type TextAreaProps = FieldProps & _TextAreaProps;

export { _InputProps as InputProps };
export { _PasswordProps as PasswordProps };
export { _TextAreaProps as TextAreaProps };

type InputFieldProps = FormikFieldProps & _InputProps;
type PasswordFieldProps = FormikFieldProps & _PasswordProps;
type TextAreaFieldProps = FormikFieldProps & _TextAreaProps;

const InternalInput = React.forwardRef(
  (
    {
      field,
      form,
      meta,
      onChange: _onChange,
      onBlur: _onBlur,
      ...restProps
    }: InputProps,
    ref: React.Ref<InputRef>,
  ) => {
    const {
      value, onChange, onBlur, name,
    } = field;
    return (
      <_Input
        name={name}
        ref={ref}
        value={value}
        onChange={useCallback<NonNullable<_InputProps['onChange']>>((event) => {
          onChange(event);
          if (_onChange) {
            _onChange(event);
          }
        }, [_onChange])}
        onBlur={useCallback<NonNullable<_InputProps['onBlur']>>((event) => {
          onBlur(event);
          if (_onBlur) {
            _onBlur(event);
          }
        }, [])}
        {...restProps}
      />
    );
  },
);

InternalInput.displayName = 'InternalInput';

const InternalInputMemo = memoField(InternalInput);

const InternalInputField = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: InputFieldProps,
    ref: React.Ref<InputRef>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <InternalInputMemo ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

InternalInputField.displayName = 'InternalInputField';

const Password = React.forwardRef(
  (
    {
      field,
      form,
      meta,
      onChange: _onChange,
      onBlur: _onBlur,
      ...restProps
    }: PasswordProps,
    ref: React.Ref<InputRef>,
  ) => {
    const {
      value, onChange, onBlur, name,
    } = field;
    return (
      <_Input.Password
        name={name}
        ref={ref}
        value={value}
        onChange={useCallback<NonNullable<_PasswordProps['onChange']>>((event) => {
          onChange(event);
          if (_onChange) {
            _onChange(event);
          }
        }, [_onChange])}
        onBlur={useCallback<NonNullable<_PasswordProps['onBlur']>>((event) => {
          onBlur(event);
          if (_onBlur) {
            _onBlur(event);
          }
        }, [_onBlur])}
        {...restProps}
      />
    );
  },
);

Password.displayName = 'Password';

const PasswordMemo = memoField(Password);

const PasswordFiled = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: PasswordFieldProps,
    ref: React.Ref<InputRef>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <PasswordMemo ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

PasswordFiled.displayName = 'PasswordFiled';

const TextArea = React.forwardRef(
  (
    {
      field,
      form,
      meta,
      onChange: _onChange,
      onBlur: _onBlur,
      ...restProps
    }: TextAreaProps,
    ref: React.Ref<TextAreaRef>,
  ) => {
    const {
      value, onChange, onBlur, name,
    } = field;
    return (
      <_Input.TextArea
        name={name}
        ref={ref}
        value={value}
        onChange={useCallback<NonNullable<_TextAreaProps['onBlur']>>((event) => {
          onChange(event);
          if (_onChange) {
            _onChange(event);
          }
        }, [_onChange])}
        onBlur={useCallback<NonNullable<_TextAreaProps['onBlur']>>((event) => {
          onBlur(event);
          if (_onBlur) {
            _onBlur(event);
          }
        }, [_onBlur])}
        {...restProps}
      />
    );
  },
);

TextArea.displayName = 'TextArea';

const TextAreaMemo = memoField(TextArea);

const TextAreaField = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: TextAreaFieldProps,
    ref: React.Ref<TextAreaRef>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <TextAreaMemo ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

TextAreaField.displayName = 'TextAreaField';

export interface InputType
  // eslint-disable-next-line max-len
  extends React.MemoExoticComponent<React.ForwardRefExoticComponent<FieldProps & _InputProps & React.RefAttributes<InputRef>>> {
  Password: typeof PasswordMemo;
  TextArea: typeof TextAreaMemo;
}

export const Input = InternalInputMemo as InputType;
Input.Password = PasswordMemo;
Input.TextArea = TextAreaMemo;

export interface InputFieldType
  extends React.ForwardRefExoticComponent<InputFieldProps & _InputProps & React.RefAttributes<InputRef>> {
  Password: typeof PasswordFiled;
  TextArea: typeof TextAreaField;
}

export const InputField = InternalInputField as InputFieldType;
InputField.Password = PasswordFiled;
InputField.TextArea = TextAreaField;

export const InputWrapper = makeField<_InputProps>(Input);
export const InputPasswordWrapper = makeField<_PasswordProps>(Input.Password);
export const TextAreaWrapper = makeField<_TextAreaProps>(Input.TextArea);
