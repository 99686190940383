import { takeEvery, call, put } from 'redux-saga/effects';

import { createProductGroup, deleteProductGroup, ResponseInterface, updateProductGroup } from '../api';

import { fetchProducts } from "../actions/productsActions";

import { CREATE_PRODUCT_GROUP, DELETE_PRODUCT_GROUP, UPDATE_PRODUCT_GROUP } from "../actions/productGroupsActions";

// Saga workers

export function* createProductGroupWorker(action: any) {
  const response: ResponseInterface = yield call(createProductGroup, action.payload.productGroup);
  if (response.status === 'OK') {
    yield put(fetchProducts());
    action.meta.resolve();
    return response.payload.products;
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
}

export function* updateProductGroupWorker(action: any) {
  const response: ResponseInterface = yield call(updateProductGroup, action.payload.id, action.payload.productGroup);
  if (response.status === 'OK') {
    yield put(fetchProducts());
    action.meta.resolve();
    return response.payload.products;
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
}

export function* deleteProductGroupWorker(action: any) {
  const response: ResponseInterface = yield call(deleteProductGroup, action.payload.id);
  if (response.status === 'OK') {
    yield put(fetchProducts());
    action.meta.resolve();
    return response.payload.products;
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
}

// Saga watchers

export default function* users() {
  yield takeEvery(CREATE_PRODUCT_GROUP, createProductGroupWorker);
  yield takeEvery(UPDATE_PRODUCT_GROUP, updateProductGroupWorker);
  yield takeEvery(DELETE_PRODUCT_GROUP, deleteProductGroupWorker);
}
