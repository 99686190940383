import React from 'react';

import PurchasesPage from './PurchasesPage';
import {
  ProductSummaryListProvider, useProductSummaryListDispatch,
} from './products-summary/context/productSummaryListContext';
import { useCallback } from 'react';
import { getList } from './products-summary/context/productsSumaryListThunks';
import { useAuthState } from '../authentication/context/authContext';
import { currentUserSelector } from '../authentication/context/authSelectors';

export const PurchasesPageContainer = () => {
  const dispatch = useProductSummaryListDispatch();
  return (
    <PurchasesPage
      loadPurchases={useCallback(async () => (
        dispatch(getList)
      ), [dispatch])}
    />
  );
};

export const PurchasesPageWrapper = () => {
  const state = useAuthState();
  const { accountId } = currentUserSelector(state)!;
  return (
    <ProductSummaryListProvider initState={{ accountId }}>
      <PurchasesPageContainer />
    </ProductSummaryListProvider>
  );
};

export default PurchasesPageWrapper;
