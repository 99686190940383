import { produce } from 'immer';
import {
  Account,
  accountInitialValues,
  BankAccount,
  bankAccountInitialValues, IpBeneficiary,
  UlBeneficiary,
  ulInitialValues,
} from '../form';

export enum SignupStep {
  MainInfo = 'mainInfo',
  BeneficiaryInfo = 'beneficiaryInfo',
  BankAccountInfo = 'bankAccountInfo',
}

export interface SignupContextState {
  step: SignupStep,
  bankAccountInfo: BankAccount;
  mainInfo: Account;
  beneficiaryInfo: UlBeneficiary | IpBeneficiary;
}

// eslint-disable-next-line no-unused-vars
export const initialState = (initState: Partial<SignupContextState> = {}): SignupContextState => ({
  step: SignupStep.MainInfo,
  bankAccountInfo: bankAccountInitialValues,
  mainInfo: accountInitialValues,
  beneficiaryInfo: ulInitialValues,
});

export const SET_STEP = 'SET_STEP';
export const setStep = (step: SignupStep) => ({
  type: SET_STEP, payload: { step },
});

export const SET_BANK_ACCOUNT_INFO = 'SET_BANK_ACCOUNT_INFO';
export const setBankAccountInfo = (bankAccountInfo: BankAccount) => ({
  type: SET_BANK_ACCOUNT_INFO, payload: { bankAccountInfo },
});

export const SET_MAIN_INFO = 'SET_MAIN_INFO';
export const setMainInfo = (mainInfo: Account) => ({
  type: SET_MAIN_INFO, payload: { mainInfo },
});

export const SET_BENEFICIARY_INFO = 'SET_BENEFICIARY_INFO';
export const setBeneficiaryInfo = (beneficiaryInfo: UlBeneficiary | IpBeneficiary) => ({
  type: SET_BENEFICIARY_INFO, payload: { beneficiaryInfo },
});

export const signupReducer = (state: SignupContextState, action: any) => produce(state, (draftState) => {
  switch (action.type) {
    case SET_STEP: {
      const { step } = (action as ReturnType<typeof setStep>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.step = step;
      break;
    }
    case SET_BANK_ACCOUNT_INFO: {
      const { bankAccountInfo } = (action as ReturnType<typeof setBankAccountInfo>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.bankAccountInfo = bankAccountInfo;
      break;
    }
    case SET_MAIN_INFO: {
      const { mainInfo } = (action as ReturnType<typeof setMainInfo>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.mainInfo = mainInfo;
      break;
    }
    case SET_BENEFICIARY_INFO: {
      const { beneficiaryInfo } = (action as ReturnType<typeof setBeneficiaryInfo>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.beneficiaryInfo = beneficiaryInfo;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default signupReducer;
