import React from 'react';
import { notification } from 'antd';

import BeneficiaryUpdateTask from './BeneficiaryUpdateTask';
import { getWorkflow, registerBeneficiary, updateWorkflow } from '../../../../api/partnerRegistration';

type BeneficiaryUpdateTaskContainerProps = {
  workflowId: string;
}

const BeneficiaryUpdateTaskContainer = ({ workflowId }: BeneficiaryUpdateTaskContainerProps) => (
  <BeneficiaryUpdateTask
    workflowId={workflowId}
    onLoad={async () => (
      getWorkflow(workflowId)
    )}
    onUpdate={async (data: any) => {
      try {
        await updateWorkflow(workflowId, data);
        return true;
      } catch (error: any) {
        notification.error({ message: error.message });
        return false;
      }
    }}
    onApprove={async () => {
      try {
        // eslint-disable-next-line max-len
        await registerBeneficiary(workflowId);
        return true;
      } catch (error: any) {
        notification.error({ message: error.message });
        return false;
      }
    }}
  />
);

export {
  BeneficiaryUpdateTaskContainer,
};
