import { Dispatch } from 'react';
import { notification } from 'antd';

import { AuthenticationApi } from 'api';
import { container } from 'ioc-container/container';
import { Providers } from 'ioc-container/providers';
import { AuthEventType } from 'services/ComponentNotificationService';
import {
  AuthContextState,
  setAuthenticated,
  setAuthenticating,
  setCurrentUser,
  setSessionUser, setUserRoles,
} from './authReducer';
import {
  currentUserSelector,
  isAuthenticatedSelector,
  sessionUserSelector,
} from './authSelectors';
import { ChangePasswordDto } from '../../../api/authentication/authenticationTypes';
import { Role } from '../../../constants/rbac';
// import { KapitalUser, UserPermission, UserPermissionAction } from '../../../api/user/userTypes';

export const checkUser = async (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  const state = getState();
  const sessionUser = sessionUserSelector(state);
  const currentUser = currentUserSelector(state);

  const result = await AuthenticationApi.me(false);
  if (result.sessionUser.id !== sessionUser?.id || result.currentUser.id !== currentUser?.id) {
    window.location.href = '/';
  }
};

const finishAuthentication = async (dispatch: Dispatch<any>) => {
  const me = await AuthenticationApi.me();

  const rbacManager = container.resolve('rbacManager');
  await rbacManager.loadCache();

  const isAdministrator = await rbacManager.checkAccess(me.currentUser.id, Role.Administrator);
  const isOrganizer = await rbacManager.checkAccess(me.currentUser.id, Role.Organizer);
  const isDistributor = await rbacManager.checkAccess(me.currentUser.id, Role.Distributor);
  const isInspector = await rbacManager.checkAccess(me.currentUser.id, Role.Inspector);
  const isMultiticketManager = await rbacManager.checkAccess(me.currentUser.id, Role.MultiticketManager);

  dispatch(setUserRoles({
    [Role.Administrator]: isAdministrator,
    [Role.Organizer]: isOrganizer,
    [Role.Distributor]: isDistributor,
    [Role.Inspector]: isInspector,
    [Role.MultiticketManager]: isMultiticketManager,
  }));

  dispatch(setSessionUser(me.sessionUser));
  dispatch(setCurrentUser(me.currentUser));
  dispatch(setAuthenticated(true));
};

export const signIn = (email: string, password: string, rememberMe: boolean) => (
  async (dispatch: Dispatch<any>) => {
    const result = await AuthenticationApi.signIn(email, password, rememberMe);
    await dispatch(finishAuthentication);
    window.location.href = '/';
    return result;
  }
);

export const signOut = async (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  container.resolve(Providers.componentNotificationService).emit(AuthEventType.UserLogout);
  const state = getState();
  const sessionUser = sessionUserSelector(state);
  const userProfile = currentUserSelector(state);
  if (sessionUser?.id && sessionUser?.id !== userProfile?.id) {
    await AuthenticationApi.loginAsUser(null);
    window.location.reload();
    return;
  }
  await AuthenticationApi.signOut();
  dispatch(setAuthenticated(false));
};

export const authenticate = async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setAuthenticating(true));
    await dispatch(finishAuthentication);
  } catch (error) {
    dispatch(setAuthenticated(false));
  } finally {
    dispatch(setAuthenticating(false));
  }
};

export const changePassword = (changePasswordDto: ChangePasswordDto) => async () => {
  const result = await AuthenticationApi.changePassword(changePasswordDto);
  return result;
};

export const restorePassword = (email: string) => async () => {
  const result = await AuthenticationApi.restorePassword(email);
  return result;
};

export const processUnauthorizedRequest = (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  const state = getState();
  const isAuthenticated = isAuthenticatedSelector(state);
  if (!isAuthenticated) {
    return;
  }
  dispatch(setAuthenticated(false));
  notification.error({
    message: 'Unauthorized',
    description: 'Your access token has expired. Please login again.',
  });
};
