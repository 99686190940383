import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography, Form, Button } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { SelectWrapper } from 'utils/form-helpers/antd-formik';

import { ApplicationLayout } from '../../application/layouts/application-layout/ApplicationLayout';

import './LoginAsPage.scss';

export interface Props {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any;
  users: Array<{
    value: string;
    label: string;
  }>;
}

export const LoginAsPage = ({ onSubmit, users }: Props) => (
  <ApplicationLayout>
    <Helmet>
      <title>Зайти под...</title>
    </Helmet>
    <div className="LoginAsPage">
      <div className="LoginAsPage-header">
        <Typography.Title level={3} className="InspectorsPage-header-title">Зайти под пользователем</Typography.Title>
      </div>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <div className="LoginAsUser">
            <Form layout="vertical">
              <SelectWrapper
                antFieldProps={{ label: 'Пользователь', className: 'LoginAsPage-select' }}
                name="userId"
                options={users}
                placeholder="Выберите пользователя"
                allowClear
                optionFilterProp="label"
                showSearch
              />
              <div className="LoginAsUser-buttonSection">
                <Button
                  onClick={() => handleSubmit()}
                  type="primary"
                  loading={isSubmitting}
                >
                  Войти
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  </ApplicationLayout>
);
