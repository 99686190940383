import React from 'react';

import AuthenticatedRoutes from './AuthenticatedRoutes';
import { useAuthState } from '../../authentication/context/authContext';
import { createIsUserRoleSelector } from '../../authentication/context/authSelectors';
import { Role } from '../../../constants/rbac';

export const AuthenticatedRoutesContainer = () => {
  const state = useAuthState();
  return (
    <AuthenticatedRoutes
      isAdministrator={createIsUserRoleSelector(Role.Administrator)(state)}
      isOrganizer={createIsUserRoleSelector(Role.Organizer)(state)}
      isDistributor={createIsUserRoleSelector(Role.Distributor)(state)}
      isInspector={createIsUserRoleSelector(Role.Inspector)(state)}
    />
  );
};

export default AuthenticatedRoutesContainer;
