import React from 'react';

import './NonAuthLayout.scss';

interface Props {
  children: React.ReactNode;
}

const NonAuthLayout = ({ children }: Props) => (
  <div className="NonAuthLayout">
    <div className="NonAuthLayout-content">
      {children}
    </div>
  </div>
);

export default NonAuthLayout;
