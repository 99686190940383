import * as yup from 'yup';
import { InferType } from 'yup';

import { AccountTax } from '../../../../constants/business';
import { BeneficiaryType, Citizenship, DocumentType, RegistrationType } from '../../../../constants/tinkoff';

export const accountValidationSchema = yup.object({
  parentAccount: yup.string().optional(),
  parentAccountVerified: yup.boolean().default(false),
  // Данные пользователя
  emailVerified: yup.boolean().default(false),
  email: yup.string()
    .email('Неверный почтовый адрес')
    .required('Требуется почта'),
  // Общая информация об организации
  organizationEmailVerified: yup.boolean().default(false),
  organizationEmail: yup.string()
    .email('Неверный почтовый адрес')
    .required('Требуется почта'),
  innVerified: yup.boolean().default(false),
  inn: yup.string()
    .matches(/^\d{10,12}$/, 'Неверный ИНН')
    .required('ИНН является обязательным к заполнению.'),
  kppVerified: yup.boolean().default(false),
  kpp: yup.string()
    .matches(/(^(\d{9})?$)/, 'Неверный КПП')
    .transform((value) => (value === '' ? undefined : value)),
  ogrnVerified: yup.boolean().default(false),
  ogrn: yup.string()
    .matches(/^((1|5)\d{12})|(3\d{14})$/, 'Неверный ОГРН')
    .required('ОГРН обязателен к заполнению'),
  fullNameVerified: yup.boolean().default(false),
  fullName: yup.string()
    .min(5, 'Минимум 5 символов')
    .required('Введите полное название организации'),
  nameVerified: yup.boolean().default(false),
  name: yup.string()
    .required('Введите сокращенное название'),
  billingDescriptorVerified: yup.boolean().default(false),
  billingDescriptor: yup.string()
    .matches(/^[A-Z0-9_]{3,14}$/, 'Неверный формат, разрешены символы [A-Z0-9_]')
    .required('Укажите название'),
  siteUrlVerified: yup.boolean().default(false),
  siteUrl: yup.string()
    .url('Некорректный веб адрес')
    .required('Укажите вебсайт'),
  // Данные директора
  ceo: yup.object({
    lastNameVerified: yup.boolean().default(false),
    lastName: yup.string()
      .matches(/^[а-яА-Я -]+$/, 'Введены символы не принадлежащие алфавиту')
      .required('Укажите фамилию директора'),
    firstNameVerified: yup.boolean().default(false),
    firstName: yup.string()
      .matches(/^[а-яА-Я -]+$/, 'Введены символы не принадлежащие алфавиту')
      .required('Укажите имя директора'),
    middleNameVerified: yup.boolean().default(false),
    middleName: yup.string()
      .matches(/^[а-яА-Я -]+$/, 'Введены символы не принадлежащие алфавиту')
      .required('Укажите отчество директора'),
  })
    .required('Укажите главного управляющего компанией'),
  // Адрес
  addresses: yup.array().of(yup.object({
    typeVerified: yup.boolean().default(false),
    type: yup.string()
      .oneOf(['legal', 'actual', 'post', 'other'])
      .required('Укажите тип адреса'),
    countryVerified: yup.boolean().default(false),
    country: yup.string()
      .oneOf(['RUS'])
      .required('Укажите страну'),
    zipVerified: yup.boolean().default(false),
    zip: yup.string()
      .required('Укажите почтовый индекс'),
    cityVerified: yup.boolean().default(false),
    city: yup.string()
      .required('Укажите город или населенный пункт'),
    streetVerified: yup.boolean().default(false),
    street: yup.string()
      .required('Укажите адрес'),
    description: yup.string(),
  }))
    .min(1)
    .required(),
  // Телефоны
  phones: yup.array().of(yup.object({
    typeVerified: yup.boolean().default(false),
    type: yup.string()
      .oneOf(['common', 'fax', 'other'])
      .required('Укажите тип телефона'),
    phoneVerified: yup.boolean().default(false),
    phone: yup.string()
      .matches(/^\+7\d{8,12}$/, 'Номер должен состоять из символа +7 в начале и цифр')
      .required('Укажите номер телефона'),
    descriptionVerified: yup.boolean().default(true),
    description: yup.string()
      .required('Опишите, что это за телефон'),
  })),
  // Банковские детали
  bankAccount: yup.object({
    accountVerified: yup.boolean().default(false),
    account: yup.string()
      .matches(/^\d+$/, 'Банковский счет должен состоять из цифр')
      .required('Укажите банковский счет'),
    korAccountVerified: yup.boolean().default(false),
    korAccount: yup.string()
      .matches(/^\d+$/, 'Корреспондентский счет должен состоять из цифр')
      .required('Укажите корреспондентский счет'),
    bikVerified: yup.boolean().default(false),
    bik: yup.string()
      .matches(/^0\d+$/, 'БИК должен состоять из цифр')
      .required('Укажите БИК'),
    bankNameVerified: yup.boolean().default(false),
    bankName: yup.string()
      .required('Укажите название банка'),
    details: yup.string()
      .required('Укажите назначение платежа'),
    tax: yup.number()
      .default(0),
    kbk: yup.string(),
    kbkVerified: yup.boolean().default(false),
    oktmo: yup.string(),
    oktmoVerified: yup.boolean().default(false),
  })
    .required(),
  account: yup.object({
    tax: yup.string().oneOf(Object.values(AccountTax)).required(),
    taxVerified: yup.boolean().default(false),
  })
    .required(),
});

export const ipBeneficiaryValidationSchema = yup.object({
  type: yup.mixed<BeneficiaryType>()
    .oneOf([BeneficiaryType.IpResident], 'Неверный вид компании')
    .required('Укажите вид компании'),
  typeVerified: yup.boolean().default(false),
  firstName: yup.string().required('Укажите имя владельца'),
  firstNameVerified: yup.boolean().default(false),
  middleName: yup.string(),
  middleNameVerified: yup.boolean().default(false),
  lastName: yup.string().required('Укажите фамилию владельца'),
  lastNameVerified: yup.boolean().default(false),
  birthDate: yup.string().required('Укажите день рождения владельа'),
  birthDateVerified: yup.boolean().default(false),
  birthPlace: yup.string(),
  birthPlaceVerified: yup.boolean().default(false),
  citizenship: yup.string()
    .oneOf([Citizenship.Russia])
    .required('Укажите гражданство'),
  citizenshipVerified: yup.boolean().default(false),
  phoneNumber: yup.string(),
  phoneNumberVerified: yup.boolean().default(false),
  email: yup.string().email(),
  emailVerified: yup.boolean().default(false),
  documents: yup.array()
    .of(yup.object({
      type: yup.string()
        .oneOf([DocumentType.Passport])
        .required(),
      serial: yup.string().required('Укажите серию'),
      serialVerified: yup.boolean().default(false),
      number: yup.string().required('Укажите номер'),
      numberVerified: yup.boolean().default(false),
      date: yup.string().required('Укажите дату'),
      dateVerified: yup.boolean().default(false),
      division: yup.string().matches(/^(\d{3}-\d{3})$/).required('Укажите код подразделения'),
      divisionVerified: yup.boolean().default(false),
    }))
    .min(1)
    .required('Укажите документ удостоверяющий документ'),
  addresses: yup.array()
    .of(yup.object({
      type: yup.string()
        .oneOf([RegistrationType.RegistrationAddress])
        .required(),
      address: yup.string().required('Укажите адрес регистрации'),
      addressVerified: yup.boolean().default(false),
    }))
    .min(1)
    .required('Укажите адрес регистрации'),
  registrationDate: yup.string()
    .required('Укажите дату регистрации'),
  registrationDateVerified: yup.boolean().default(false),
  inn: yup.string()
    .matches(/^\d{12}$/, 'Неверный ИНН')
    .required('ИНН является обязательным к заполнению.'),
  innVerified: yup.boolean().default(false),
  ogrn: yup.string()
    .matches(/^|(3\d{14})$/, 'Неверный ОГРН'),
  ogrnVerified: yup.boolean().default(false),
});

export const ulBeneficiaryValidationSchema = yup.object({
  type: yup.mixed<BeneficiaryType>()
    .oneOf([BeneficiaryType.UlResident], 'Неверный вид компании')
    .required('Укажите вид компании'),
  typeVerified: yup.boolean().default(false),
  // Наименование организации
  name: yup.string(),
  nameVerified: yup.boolean().default(false),
  phoneNumber: yup.string(),
  phoneNumberVerified: yup.boolean().default(false),
  email: yup.string().email(),
  emailVerified: yup.boolean().default(false),
  addresses: yup.array().of(yup.object({
    type: yup.string().oneOf([RegistrationType.LegalEntityAddress]).required(),
    address: yup.string().required('Укажите адрес'),
    addressVerified: yup.boolean().default(false),
  }))
    .min(1)
    .required(),
  registrationDate: yup.string(),
  registrationDateVerified: yup.boolean().default(false),
  inn: yup.string()
    .matches(/^\d{10}$/, 'Неверный ИНН')
    .required('ИНН является обязательным к заполнению.'),
  innVerified: yup.boolean().default(false),
  ogrn: yup.string()
    .matches(/^((1|5)\d{12})$/, 'Неверный ОГРН'),
  ogrnVerified: yup.boolean().default(false),
});

export const accountVerificationSchema = yup.object({
  emailVerified: yup.boolean().oneOf([true]).required(),
  organizationEmailVerified: yup.boolean().oneOf([true]).required(),
  innVerified: yup.boolean().oneOf([true]).required(),
  kppVerified: yup.boolean().oneOf([true]).required(),
  ogrnVerified: yup.boolean().oneOf([true]).required(),
  fullNameVerified: yup.boolean().oneOf([true]).required(),
  nameVerified: yup.boolean().oneOf([true]).required(),
  billingDescriptorVerified: yup.boolean().oneOf([true]).required(),
  siteUrlVerified: yup.boolean().oneOf([true]).required(),
  ceo: yup.object({
    lastNameVerified: yup.boolean().oneOf([true]).required(),
    firstNameVerified: yup.boolean().oneOf([true]).required(),
    middleNameVerified: yup.boolean().oneOf([true]).required(),
  }),
  addresses: yup.array().of(yup.object({
    typeVerified: yup.boolean().oneOf([true]).required(),
    countryVerified: yup.boolean().oneOf([true]).required(),
    zipVerified: yup.boolean().oneOf([true]).required(),
    cityVerified: yup.boolean().oneOf([true]).required(),
    streetVerified: yup.boolean().oneOf([true]).required(),
  })),
  phones: yup.array().of(yup.object({
    typeVerified: yup.boolean().oneOf([true]).required(),
    phoneVerified: yup.boolean().oneOf([true]).required(),
    descriptionVerified: yup.boolean().oneOf([true]).required(),
  })),
  bankAccount: yup.object({
    accountVerified: yup.boolean().oneOf([true]).required(),
    korAccountVerified: yup.boolean().oneOf([true]).required(),
    bikVerified: yup.boolean().oneOf([true]).required(),
    bankNameVerified: yup.boolean().oneOf([true]).required(),
    kbkVerified: yup.boolean().oneOf([true]).required(),
    oktmoVerified: yup.boolean().oneOf([true]).required(),
  }),
  account: yup.object({
    taxVerified: yup.boolean().oneOf([true]).required(),
  }),
});

export const ipBeneficiaryVerificationSchema = yup.object({
  typeVerified: yup.boolean().oneOf([true]).required(),
  firstNameVerified: yup.boolean().oneOf([true]).required(),
  middleNameVerified: yup.boolean().oneOf([true]).required(),
  lastNameVerified: yup.boolean().oneOf([true]).required(),
  birthDateVerified: yup.boolean().oneOf([true]).required(),
  birthPlaceVerified: yup.boolean().oneOf([true]).required(),
  citizenshipVerified: yup.boolean().oneOf([true]).required(),
  phoneNumberVerified: yup.boolean().oneOf([true]).required(),
  emailVerified: yup.boolean().oneOf([true]).required(),
  documents: yup.array()
    .of(yup.object({
      serialVerified: yup.boolean().oneOf([true]).required(),
      numberVerified: yup.boolean().oneOf([true]).required(),
      dateVerified: yup.boolean().oneOf([true]).required(),
      divisionVerified: yup.boolean().oneOf([true]).required(),
    }))
    .min(1)
    .required('Укажите документ удостоверяющий документ'),
  addresses: yup.array()
    .of(yup.object({
      addressVerified: yup.boolean().oneOf([true]).required(),
    }))
    .min(1)
    .required('Укажите адрес регистрации'),
  registrationDateVerified: yup.boolean().oneOf([true]).required(),
  innVerified: yup.boolean().oneOf([true]).required(),
  ogrnVerified: yup.boolean().oneOf([true]).required(),
});

export const ulBeneficiaryVerificationSchema = yup.object({
  typeVerified: yup.boolean().oneOf([true]).required(),
  nameVerified: yup.boolean().oneOf([true]).required(),
  phoneNumberVerified: yup.boolean().oneOf([true]).required(),
  emailVerified: yup.boolean().oneOf([true]).required(),
  addresses: yup.array().of(yup.object({
    addressVerified: yup.boolean().oneOf([true]).required(),
  }))
    .min(1)
    .required(),
  registrationDateVerified: yup.boolean().oneOf([true]).required(),
  innVerified: yup.boolean().oneOf([true]).required(),
  ogrnVerified: yup.boolean().oneOf([true]).required(),
});

export type UlBeneficiary = InferType<typeof ulBeneficiaryValidationSchema>;
export const ulBeneficiaryInitialValues: UlBeneficiary = {
  type: BeneficiaryType.UlResident,
  typeVerified: false,
  name: undefined,
  nameVerified: false,
  phoneNumber: undefined,
  phoneNumberVerified: false,
  email: undefined,
  emailVerified: false,
  addresses: [{
    type: RegistrationType.LegalEntityAddress,
    address: '',
    addressVerified: false,
  }],
  registrationDate: undefined,
  registrationDateVerified: false,
  inn: '',
  innVerified: false,
  ogrn: undefined,
  ogrnVerified: false,
};

export type IpBeneficiary = InferType<typeof ipBeneficiaryValidationSchema>;
export const ipBeneficiaryInitialValues: IpBeneficiary = {
  type: BeneficiaryType.IpResident,
  typeVerified: false,
  firstName: '',
  firstNameVerified: false,
  middleName: undefined,
  middleNameVerified: false,
  lastName: '',
  lastNameVerified: false,
  birthDate: '',
  birthDateVerified: false,
  birthPlace: undefined,
  birthPlaceVerified: false,
  citizenship: Citizenship.Russia,
  citizenshipVerified: false,
  phoneNumber: undefined,
  phoneNumberVerified: false,
  email: undefined,
  emailVerified: false,
  documents: [{
    type: DocumentType.Passport,
    serial: '',
    serialVerified: false,
    number: '',
    numberVerified: false,
    date: '',
    dateVerified: false,
    division: '',
    divisionVerified: false,
  }],
  addresses: [{
    type: RegistrationType.RegistrationAddress,
    address: '',
    addressVerified: false,
  }],
  registrationDate: '',
  registrationDateVerified: false,
  inn: '',
  innVerified: false,
  ogrn: undefined,
  ogrnVerified: false,
};

export type AccountValues = InferType<typeof accountValidationSchema>;

export const accountInitialValues: AccountValues = {
  parentAccount: undefined,
  parentAccountVerified: false,
  // Данные пользователя
  email: '',
  emailVerified: false,
  // Общая информация об организации
  organizationEmail: '',
  organizationEmailVerified: false,
  inn: '',
  innVerified: false,
  kpp: '',
  kppVerified: false,
  ogrn: '',
  ogrnVerified: false,
  fullName: '',
  fullNameVerified: false,
  name: '',
  nameVerified: false,
  billingDescriptor: '',
  billingDescriptorVerified: false,
  siteUrl: 'https://',
  siteUrlVerified: false,
  // Директор
  ceo: {
    firstName: '',
    firstNameVerified: false,
    lastName: '',
    lastNameVerified: false,
    middleName: '',
    middleNameVerified: false,
  },
  // Адреса
  addresses: [
    {
      type: 'legal',
      typeVerified: false,
      country: 'RUS',
      countryVerified: false,
      zip: '',
      zipVerified: false,
      city: '',
      cityVerified: false,
      street: '',
      streetVerified: false,
      description: '',
    },
  ],
  // Телефоны
  phones: [
    {
      type: 'common',
      typeVerified: false,
      phone: '+7',
      phoneVerified: false,
      description: 'Основной для клиентов',
      descriptionVerified: true,
    },
    {
      type: 'common',
      typeVerified: false,
      phone: '+7',
      phoneVerified: false,
      description: 'Для контактов ClickPass',
      descriptionVerified: true,
    },
  ],
  // Банковские реквизиты
  bankAccount: {
    account: '',
    accountVerified: false,
    korAccount: '',
    korAccountVerified: false,
    bik: '',
    bikVerified: false,
    bankName: '',
    bankNameVerified: false,
    // eslint-disable-next-line no-template-curly-in-string
    details: 'Перевод средств по Лицензионному Договору по Реестру Операций от ${date}. Лицензионное отчисление'
      // eslint-disable-next-line no-template-curly-in-string
      + ' ${rub} руб. ${kop} коп., НДС не облагается.',
    tax: 0,
    kbk: '',
    kbkVerified: false,
    oktmo: '',
    oktmoVerified: false,
  },
  account: {
    tax: AccountTax.NONE,
    taxVerified: false,
  },
};

export const ipValidationSchema = accountValidationSchema.shape({
  ipBeneficiary: ipBeneficiaryValidationSchema.required(),
});

export const ulValidationSchema = accountValidationSchema.shape({
  ulBeneficiary: ulBeneficiaryValidationSchema.required(),
});

export type ValidationSchema = InferType<typeof ipValidationSchema> | InferType<typeof ulValidationSchema>
export const getValidationSchema = (type: BeneficiaryType) => {
  if (type === BeneficiaryType.IpResident) {
    return ipValidationSchema;
  }
  return ulValidationSchema;
};

export const getVerificationSchema = (type: BeneficiaryType) => {
  if (type === BeneficiaryType.IpResident) {
    return accountVerificationSchema.shape({
      ipBeneficiary: ipBeneficiaryVerificationSchema.required(),
    });
  }
  return accountVerificationSchema.shape({
    ulBeneficiary: ulBeneficiaryVerificationSchema.required(),
  });
};

export const getInitialValues = (type: BeneficiaryType): ValidationSchema => {
  if (type === BeneficiaryType.IpResident) {
    return {
      ...accountInitialValues,
      ipBeneficiary: ipBeneficiaryInitialValues,
    };
  }
  return {
    ...accountInitialValues,
    ulBeneficiary: ulBeneficiaryInitialValues,
  };
};
