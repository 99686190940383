import { Button, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

import { PrivateRoutes } from '../../../constants/routes';

enum WorkflowExecutionStatus {
  WORKFLOW_EXECUTION_STATUS_UNSPECIFIED = 0,
  WORKFLOW_EXECUTION_STATUS_RUNNING = 1,
  WORKFLOW_EXECUTION_STATUS_COMPLETED = 2,
  WORKFLOW_EXECUTION_STATUS_FAILED = 3,
  WORKFLOW_EXECUTION_STATUS_CANCELED = 4,
  WORKFLOW_EXECUTION_STATUS_TERMINATED = 5,
  WORKFLOW_EXECUTION_STATUS_CONTINUED_AS_NEW = 6,
  WORKFLOW_EXECUTION_STATUS_TIMED_OUT = 7
}

interface WorkflowRow {
  id: string;
  name: string;
  status: string;
  children: {
    id: string;
    name: string;
    status: string;
  }[];
}

interface State {
  workflows: WorkflowRow[];
}

class RegistrationTasks extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      workflows: [],
    };
  }

  async componentDidMount() {
    const { onLoadRegistrationTasks } = this.props;
    const result = await onLoadRegistrationTasks();
    const hierarchWorkflows = result.workflows.map((workflow: any) => {
      const { data } = workflow.metadata;
      return {
        id: workflow.execution.workflowId,
        status: workflow.status,
        name: `${data.inn} ${data.name} ${data.email}`,
        children: workflow.children.map((childWorkflow: any) => ({
          id: childWorkflow.execution.workflowId,
          status: childWorkflow.status,
          name: childWorkflow.type.name,
        })),
      };
    });
    this.setState({ workflows: hierarchWorkflows });
  }

  columns(): ColumnsType<WorkflowRow> {
    const { navigate } = this.props;
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        width: 120,
        ellipsis: true,
      }, {
        title: 'Статус',
        dataIndex: 'status',
        width: 120,
        render: (value: WorkflowExecutionStatus) => {
          switch (value) {
            case WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_RUNNING: {
              return <Tag color="blue">В процессе</Tag>;
            }
            case WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_FAILED: {
              return <Tag color="error">Ошибка</Tag>;
            }
            case WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_COMPLETED: {
              return <Tag color="green">Завершена</Tag>;
            }
            default: {
              return null;
            }
          }
        },
      }, {
        title: 'Название',
        dataIndex: 'name',
        render: (value: string) => {
          switch (value) {
            case 'approveRegistrationWorkflowV2':
              return 'Шаг 2: Утвердить регистрацию';
            case 'verifyRegistrationWorkflowV2':
              return 'Шаг 1: Верифицировать регистрацию';
            case 'correctBeneficiaryWorkflowV2':
              return 'Исправить бенефициара';
            default:
              return value;
          }
        },
      }, {
        dataIndex: 'id',
        width: '80px',
        render: (id: string, record) => {
          if (record.children) {
            return null;
          }
          return (
            <Space>
              <Button
                icon={
                  record.status === 'WORKFLOW_EXECUTION_STATUS_RUNNING'
                    ? <EditOutlined />
                    : <EyeOutlined />
                }
                onClick={() => {
                  if (record.name === 'approveRegistrationWorkflowV2') {
                    navigate(PrivateRoutes.Partners.replace(':tab', `approve-${record.id}`));
                  }
                  if (record.name === 'verifyRegistrationWorkflowV2') {
                    navigate(PrivateRoutes.Partners.replace(':tab', `verify-${record.id}`));
                  }
                  if (record.name === 'correctBeneficiaryWorkflowV2') {
                    navigate(PrivateRoutes.Partners.replace(
                      ':tab',
                      `beneficiary-update-${record.id}`,
                    ));
                  }
                }}
              />
            </Space>
          );
        },
      },
    ];
  }

  render() {
    const { workflows } = this.state;

    return (
      <div>
        <Table<WorkflowRow>
          columns={this.columns()}
          dataSource={workflows}
          pagination={false}
          rowKey="id"
          style={{ width: '100%' }}
          // TODO: Косячит переключение в полноэкранный режим
          scroll={{ x: '100%', y: 'calc(100vh - 142px)' }}
        />
      </div>
    );
  }
}

export default RegistrationTasks;
