import { Dispatch } from 'react';

import {
  ChildPartnersContextState, setChildPartnersLoading,
  storeListItems, storePagination,
} from './childPartnersReducer';
import { paginationSelector } from './childPartnersSelectors';
import { fetchChildAccounts } from '../../../api';

export const getList = async (dispatch: Dispatch<any>, getState: () => ChildPartnersContextState) => {
  const state = getState();
  const pagination = paginationSelector(state);
  dispatch(setChildPartnersLoading(true));
  try {
    const result = await fetchChildAccounts(pagination);
    if (!result) {
      throw new Error('No data');
    }
    dispatch(storePagination({
      ...pagination,
      total: result.total,
    }));
    dispatch(storeListItems(result.accounts));
  } finally {
    dispatch(setChildPartnersLoading(false));
  }
};
