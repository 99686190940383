import { process } from '../env';
import { commonFetch, getResponse, Response } from './apiHelper';

export function fetchAccounts() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/accounts`;
  return commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}

export interface ChildAccount {
  id: string;
  name: string;
  tinkoffShop: {
    info: {
      inn: string;
    }
    created: string;
  };
}

export interface ChildAccountResponse {
  accounts: ChildAccount[];
  total: number;
}

export const fetchChildAccounts = async (pagination: {
  pageSize: number,
  current: number,
}) => {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/accounts/child-accounts`;
  const backendResponse = await commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pagination,
    }),
  });
  const response: Response<ChildAccountResponse> = await backendResponse.json();
  return getResponse(response);
};
