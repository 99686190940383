import React from 'react';
import * as Yup from 'yup';
import { DeleteOutlined } from '@ant-design/icons';

import { Modal, Select, Button, Divider, InputNumber, Checkbox } from 'antd';
import { Formik, Form, ErrorMessage } from 'formik';

import './MultiTicketProductFormModal.scss';
import { SchemaType } from '../../../../constants/schema';
import { InputField } from '../../../../utils/form-helpers/antd-formik';

const UNLIMITED_QUANTITY = -1;

export class MultiTicketProductFormModal extends React.Component {
  constructor() {
    super();
    this.state = {
      multiTicketComponents: [],
      loading: false,
    }
    this.onFinish = this.onFinish.bind(this);
  }

  async componentDidMount() {
    const { onTicketComponentsFetch } = this.props;
    this.setState({ loading: true });
    try {
      const multiTicketComponents = await onTicketComponentsFetch();
      this.setState({ multiTicketComponents });
    } finally {
      this.setState({ loading: false });
    }
  }

  async onFinish(values, { setSubmitting, setErrors }) {
    const { product, onCreateProduct, onUpdateProduct, onComplete } = this.props;
    try {
      if (product) {
        await onUpdateProduct(product.id, values);
      } else {
        await onCreateProduct(values);
      }
      onComplete();
    } catch (error) {
      setErrors(error);
      setSubmitting(false);
    }
  }

  validationSchema = Yup.object().shape({
    productGroupId: Yup.string().nullable().default(null),
    specification: Yup.object({
      type: Yup.string().default(SchemaType.MultiTicket),
      version: Yup.number().default(1),
      title: Yup.string().required('Введите название продукта.'),
      description: Yup.string().required('Введите описание продукта.'),
      componentIds: Yup.array().min(1, 'Добавьте хотя бы один компонент').of(Yup.string()),
      quantity: Yup.number().required('Введите количество билетов.'),
    }).required(),
  });

  render() {
    const { open, onCancel, productGroups, product } = this.props;
    const { multiTicketComponents } = this.state;
    const title = product ? 'Обновить товар' : 'Добавить товар';
    const okText = product ? 'Обновить' : 'Добавить';
    const defaultProduct = {
      productGroupId: null,
      specification: {
        type: SchemaType.MultiTicket,
        version: 1,
        title: 'Новый товар 1',
        description: 'Описание нового товара',
        componentIds: [],
        quantity: 100
      },
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={product || defaultProduct}
        validationSchema={this.validationSchema}
        onSubmit={this.onFinish}
      >
        {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            initialValues,
          }) => {
          return (
            <Modal
              open={open}
              title={title}
              onCancel={onCancel}
              okText={okText}
              onOk={handleSubmit}
              destroyOnClose={true}
            >
              <div className="MultiTicketProductFormModal">
                <Form>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td>
                          Название:<br />
                          <InputField name="specification.title" />
                          <ErrorMessage name={`specification.title`}>
                            {(msg) => <div className="MultiTicketProductFormModal-error">{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Описание:<br />
                          <InputField.TextArea name="specification.description" />
                          <ErrorMessage name={`specification.description`}>
                            {(msg) => <div className="MultiTicketProductFormModal-error">{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Divider />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Компоненты:
                          <br />
                          <table className="MultiTicketProductFormModal-componentTable">
                            <thead />
                            <tbody>
                              {
                                values.specification.componentIds.map((componentId, index) => (
                                  <tr key={componentId}>
                                    <td className="MultiTicketProductFormModal-componentName">
                                      {
                                        multiTicketComponents.find((component) => component.id === componentId)?.specification.title
                                      }
                                      <ErrorMessage name={`specification.componentIds[${index}]`}>
                                        {(msg) => <div
                                          className="MultiTicketProductFormModal-error">{msg}</div>}
                                      </ErrorMessage>
                                    </td>
                                    <td className="MultiTicketProductFormModal-deleteComponentButton">
                                      <Button
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                          values.specification.componentIds.splice(index, 1);
                                          setFieldValue(`specification.componentIds`, values.specification.componentIds);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                          <Select
                            value={null}
                            className="MultiTicketProductFormModal-componentSelect"
                            onChange={(value) => {
                              const newValue = values.specification.componentIds;
                              newValue.push(value);
                              setFieldValue(`specification.componentIds`, newValue);
                              return false;
                            }}
                          >
                            {
                              multiTicketComponents
                                .filter((component) => !values.specification.componentIds.includes(component.id))
                                .map((component) => (
                                  <Select.Option
                                    key={component.id}
                                    value={component.id}
                                  >
                                    {component.specification.title}
                                  </Select.Option>
                                ))
                            }
                          </Select>
                          <ErrorMessage name={`specification.componentIds`}>
                            {(msg) => (
                              typeof msg === 'string' &&
                              <div className="MultiTicketProductFormModal-error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Divider />
                        </td>
                      </tr>
                      {
                        values.specification.quantity !== UNLIMITED_QUANTITY
                        && <tr>
                          <td>
                            Количество:<br />
                            <InputNumber
                              value={values.specification.quantity}
                              onChange={(value) => setFieldValue('specification.quantity', value)}
                              onBlur={() => setFieldTouched('specification.quantity', true)}
                            />
                            <ErrorMessage name={`specification.quantity`}>
                              {(msg) => <div className="MultiTicketComponentProductFormModal-error">{msg}</div>}
                            </ErrorMessage>
                          </td>
                        </tr>
                      }
                      <tr>
                        <td>
                          <Checkbox
                            checked={values.specification.quantity === UNLIMITED_QUANTITY}
                            onChange={(e) => {
                              const defaultValue = initialValues.specification.quantity === UNLIMITED_QUANTITY ? defaultProduct.specification.quantity : initialValues.specification.quantity;
                              setFieldValue('specification.quantity', e.target.checked ? UNLIMITED_QUANTITY : defaultValue)
                            }}
                            onBlur={() => setFieldTouched('specification.quantity', true)}
                          >
                            Без ограничений
                          </Checkbox>
                          <ErrorMessage name={`specification.quantity`}>
                            {(msg) => <div className="MultiTicketComponentProductFormModal-error">{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Divider />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Группа:<br />
                          <Select
                            style={{ width: '200px' }}
                            value={values.productGroupId}
                            onChange={(value) => setFieldValue('productGroupId', value)}
                            onBlur={() => setFieldTouched('productGroupId', true)}
                          >
                            <Select.Option value={null}>Не выбрана</Select.Option>
                            {productGroups.map(productGroup => (
                              <Select.Option
                                key={productGroup.id}
                                value={productGroup.id}
                              >
                                {productGroup.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {errors?.summary &&
                    <div className="MultiTicketProductFormModal-error">{errors.summary}</div>}
                </Form>
              </div>
            </Modal>
          )
        }}
      </Formik>
    );
  }
}

export default MultiTicketProductFormModal;
