import React, { useState, useCallback } from 'react';
import { usePrevious } from 'react-use';
import classnames from 'classnames';

import './ApplicationLayout.scss';

import { HeaderContainer } from '../../header/HeaderContainer';
import MenuContainer from '../../menu/MenuContainer';

interface Props {
  children: React.ReactNode;
}

export const ApplicationLayout = ({ children }: Props) => {
  const [showSider, setShowSider] = useState(true);
  const prevShowSider = usePrevious(showSider);
  return (
    <div
      className={classnames({
        ApplicationLayout: true,
        oneColumn: !showSider,
        twoColumn: showSider && !prevShowSider,
      })}
    >
      <div className="ApplicationLayout-header">
        <HeaderContainer
          onSiderModeClick={useCallback(() => setShowSider(!showSider), [showSider])}
        />
      </div>
      <div className="ApplicationLayout-menu">
        <MenuContainer />
      </div>
      <div className="ApplicationLayout-content">
        {children}
      </div>
    </div>
  );
};

export default ApplicationLayout;
