import React, { useMemo } from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import ruRU from 'antd/locale/ru_RU';
import { AuthProvider } from '../authentication/context/authContext';

import './Application.scss';

import AuthenticatorContainer from './authenticator/AuthenticatorContainer';
import NonAuthenticatedRoutes from './routes/NonAuthenticatedRoutes';
import AuthenticatedRoutesContainer from './routes/AuthenticatedRoutesContainer';
import LoadingLayout from './layouts/loading-layout/LoadingLayout';

const Application = () => (
  <ConfigProvider
    locale={ruRU}
    theme={{
      token: {
        fontFamily: 'Open Sans',
        colorPrimary: '#4689ca',
        borderRadius: 2,
      },
    }}
  >
    <AuthProvider>
      <BrowserRouter>
        <AuthenticatorContainer
          LoadingComponent={useMemo(() => (LoadingLayout), [])}
          AuthenticatedComponent={useMemo(() => (AuthenticatedRoutesContainer), [])}
          UnAuthenticatedComponent={useMemo(() => (NonAuthenticatedRoutes), [])}
        />
      </BrowserRouter>
    </AuthProvider>
  </ConfigProvider>
);

export default Application;
