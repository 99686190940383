import { takeEvery, call, put } from 'redux-saga/effects';

import { FETCH_ACCOUNTS, setAccounts } from '../actions/accountsActions';

import { fetchAccounts } from '../api';

export function* fetchAccountsWorker() {
  const response = yield call(fetchAccounts);
  if (response.status === 'OK') {
    yield put(setAccounts(response.payload.accounts));
    return response.payload;
  } else if (response.status === 'ERROR') {
    return null;
  }
}

export default function* accounts() {
  yield takeEvery(FETCH_ACCOUNTS, fetchAccountsWorker);
}
