import React from 'react';
import { Form, Input, Button, Checkbox, Typography } from 'antd';
import { Formik } from 'formik';
import { NavLink } from 'react-router-dom';

import './SigninPage.scss';

import NonAuthLayout from '../../application/layouts/non-auth-layout/NonAuthLayout';

const { Title } = Typography;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 19,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 19,
  },
};

interface Props {
  onSignIn(email: string, password: string, remember: boolean): Promise<void>;
}

class SigninPage extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.onFinish = this.onFinish.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }

  async onFinish(values: any, { setSubmitting, setErrors }: any) {
    const { onSignIn } = this.props;
    const { email, password, remember } = values;
    try {
      await onSignIn(email, password, remember);
    } catch (error) {
      setErrors({
        summary: 'Проверьте правильность логина и пароля',
      });
      setSubmitting(false);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onValidate(values: any) {
    const errors: Record<string, string> = {};
    if (!values.email) {
      errors.email = 'Требуется почтовый адрес.';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Почтовый адрес некорректен.';
    }
    if (!values.password) {
      errors.password = 'Требуется пароль.';
    }
    return errors;
  }

  render() {
    return (
      <NonAuthLayout>
        <div className="SigninPage">
          <div className="SigninPage-form">
            <Formik
              initialValues={{
                email: '',
                password: '',
                remember: false,
              }}
              validate={this.onValidate}
              onSubmit={this.onFinish}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form {...layout} onFinish={handleSubmit}>
                  <Form.Item {...tailLayout}>
                    <Title level={1}>Вход</Title>
                  </Form.Item>
                  <Form.Item
                    label="Почта"
                    name="email"
                    validateStatus={errors && errors.email ? 'error' : undefined}
                    help={errors && errors.email && touched.email && errors.email}
                  >
                    <Input onChange={handleChange} onBlur={handleBlur} value={values.email} />
                  </Form.Item>
                  <Form.Item
                    label="Пароль"
                    name="password"
                    validateStatus={errors && errors.password ? 'error' : undefined}
                    help={errors && errors.password && touched.password && errors.password}
                  >
                    <Input.Password onChange={handleChange} onBlur={handleBlur} value={values.password} />
                  </Form.Item>
                  {
                    errors && (errors as any).summary
                    && (
                      <Form.Item {...tailLayout} style={{ color: 'red' }}>
                        {(errors as any).summary}
                      </Form.Item>
                    )
                  }
                  <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                    <Checkbox onChange={handleChange} value={values.remember}>
                      Запомнить меня
                    </Checkbox>
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" disabled={isSubmitting}>Войти</Button>
                    {/* &nbsp;&nbsp;&nbsp; */}
                    {/* - или - */}
                    {/* &nbsp;&nbsp; */}
                    {/* <NavLink to="/signup">Зарегистрироваться</NavLink> */}
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    Вы можете&nbsp;
                    <NavLink to="/restore">восстановить</NavLink>
                    &nbsp;доступ, если необходимо.
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </NonAuthLayout>
    );
  }
}

export default SigninPage;
