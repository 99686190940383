import { combineReducers } from 'redux';

import products from './productsReducer';
import accounts from './accountsReducer';
import inspectors from './ispectorsReducer';
import { productGroups } from './productGroupsReducer';

export default combineReducers({
  products,
  accounts,
  inspectors,
  productGroups,
});
