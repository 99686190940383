import { spawn, call } from 'redux-saga/effects';

import products from './productsSagas';
import productGroups from './productGroupsSagas';
import shops from './shopsSagas';
import accounts from './accountsSagas';
import inspectors from './inspectorsSaga';
import reports from './reportsSagas';
import registration from './registrationSagas';

export default function* rootSaga() {
  const sagasList = [
    products,
    productGroups,
    shops,
    accounts,
    inspectors,
    reports,
    registration
  ];

  const sagas = sagasList.map((saga) =>
    spawn(function* () {
      while (true) {
        try {
          yield call(saga);
          break;
        } catch (e) {
          console.error(e); // eslint-disable-line
        }
      }
    }),
  );

  for (let i = 0; i < sagas.length; i++) {
    yield sagas[i];
  }
}
