import React, { Dispatch } from 'react';
import { thunk } from 'redux-thunk';
import { createReducer } from 'react-use';

import childPartnersReducer, {
  ChildPartnersContextState,
  initialState,
} from './childPartnersReducer';

const useThunkReducer = createReducer<any, ChildPartnersContextState>(thunk as any);

const ChildPartnersStateContext = React.createContext<ChildPartnersContextState>(initialState());
export const ChildPartnersDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode,
}

const ChildPartnersProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useThunkReducer(childPartnersReducer, initialState());

  return (
    <ChildPartnersStateContext.Provider value={state}>
      <ChildPartnersDispatchContext.Provider value={dispatch}>
        {children}
      </ChildPartnersDispatchContext.Provider>
    </ChildPartnersStateContext.Provider>
  );
};

function useChildPartnersState() {
  const context = React.useContext(ChildPartnersStateContext);
  if (context === undefined) {
    throw new Error('useChildPartnersState must be used within a ChildPartnersStateContext');
  }
  return context;
}

function useChildPartnersDispatch() {
  const context = React.useContext(ChildPartnersDispatchContext);
  if (context === undefined) {
    throw new Error('useChildPartnersDispatch must be used within a ChildPartnersDispatchContext');
  }
  return context;
}

export {
  ChildPartnersProvider,
  useChildPartnersState,
  useChildPartnersDispatch,
};
